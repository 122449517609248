import {useEffect} from "react";
import * as Sentry from "@sentry/react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router";

declare global {
    interface Window {
        sentry: {
            dsn: string;
            env: string;
            release: string;
        }
    }
}

Sentry.init({
    dsn: window.sentry.dsn,
    environment: window.sentry.env,
    release: window.sentry.release,
    integrations: [
        Sentry.reactRouterV7BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.browserSessionIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.captureConsoleIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 0.05,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', '127.0.0.1', /^\//],

    // Capture Replay for 0% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
});
