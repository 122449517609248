import * as React from "react";
import {useState} from "react";
import Popover from "@mui/material/Popover";
import {Box, Grid2 as Grid, IconButton, Tooltip} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from "@mui/icons-material/Circle";

export const RowColors = [
    "#795448",
    "#e67c73",
    "#d50201",
    "#f4511e",
    "#ef6d00",
    "#f09300",
    "#f6bf25",
    "#e4c442",
    "#c0ca33",
    "#7cb343",
    "#029688",
    "#0b8043",
    "#33b679",
    "#039be5",
    "#4185f4",
    "#3f51b5",
    "#7986cb",
    "#b39ddb",
    "#9e69af",
    "#8e24aa",
    "#d81b60",
    "#ad1357",
    "#a79b8e",
    "#616161",
];

export const EventColors = [
    "#d50201",
    "#e67c73",
    "#f4511e",
    "#f6bf25",
    "#33b679",
    "#0b8043",
    "#039be5",
    "#7986cb",
    "#3f51b5",
    "#8e24aa",
    "#616161",
];

export function ColorSelector({tooltipTitle, currentColor, availableColors, onChange, onClose, disabled}: {
    tooltipTitle: string
    currentColor: string,
    availableColors: string[],
    onChange: (color: string) => Promise<void>,
    onClose: () => Promise<void>,
    disabled?: boolean,
}) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleSelectClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = async () => {
        setAnchorEl(null);
        await onClose();
    };

    const handleColorSelected = async (color: string) => {
        await handleClose();
        await onChange(color);
    }

    const colorButtons = availableColors.map(color => {
        return (
            <Grid key={color} size={1}>
                <IconButton size="small" onClick={() => handleColorSelected(color)}>
                    {currentColor.toLowerCase() === color
                        ? <CheckCircleIcon sx={{color: color}}/>
                        : <CircleIcon sx={{color: color}}/>
                    }
                </IconButton>
            </Grid>
        );
    });

    const arrangedButtons = [];
    for (let i = 0; i < availableColors.length / 6; i++) {
        // Arrange in colors of 6 buttons in snake like pattern
        const buttonsSlice = colorButtons.slice(i * 6, i * 6 + 6);
        arrangedButtons.push(i % 2 ? buttonsSlice : buttonsSlice.reverse());
    }

    return (
        <Box>
            <Tooltip title={tooltipTitle}>
                <IconButton size="small" onClick={handleSelectClick} disabled={disabled}>
                    <CircleIcon sx={{color: currentColor}}/>
                </IconButton>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Grid container columns={6} sx={{width: 220, m: 1}}>
                    {arrangedButtons}
                </Grid>
            </Popover>
        </Box>
    );
}
