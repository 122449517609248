import * as React from "react";
import {useState} from "react";
import {Autocomplete, debounce, TextField} from "@mui/material";
import {requestPeopleScopes} from "../../../../clients/oauth";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import {searchCalendars, TeamCalAPIError} from "../../../../clients/teamcal-api";

enum AutoCompleteTypes {
    Text,
    NoResults,
    NoPermissions,
}

interface AutoCompleteOption {
    type: AutoCompleteTypes;
    text?: string;
}

async function fetchAutoCompleteData(
    searchText: string,
    setOptions: React.Dispatch<React.SetStateAction<AutoCompleteOption[]>>,
) {
    if (searchText.length === 0) {
        setOptions([{type: AutoCompleteTypes.NoResults}]);
    } else {
        try {
            const calendars = await searchCalendars(searchText, "contacts,calendars", true, 5);
            const emails = calendars.map(cal => cal.id).sort((c1, c2) => {
                return c1.toLowerCase().localeCompare(c2.toLowerCase());
            });

            if (emails.length === 0) {
                setOptions([{type: AutoCompleteTypes.NoResults}]);
            } else {
                setOptions(emails.map(email => {
                    return {type: AutoCompleteTypes.Text, text: email}
                }));
            }
        } catch (error) {
            if (error instanceof TeamCalAPIError && error.details) {
                if (error.details.code === "ContactsPermissionsMissing") {
                    setOptions([{type: AutoCompleteTypes.NoPermissions}]);
                }
            }
        }
    }
}

const debouncedFetchAutoCompleteData = debounce(fetchAutoCompleteData, 400);

export function CalendarEmailAutocomplete(
    {
        label,
        inputValue,
        onInputValueChanged,
    }: {
        label: string,
        inputValue: string,
        onInputValueChanged: (value: string) => void,
    }) {
    const [autocompleteOptions, setAutocompleteOptions] = useState<AutoCompleteOption[]>([]);

    if (inputValue === "" && autocompleteOptions.length > 0) {
        setAutocompleteOptions([]);
    }

    return (
        <Autocomplete
            fullWidth
            freeSolo
            autoComplete
            disableClearable
            includeInputInList
            filterOptions={x => x}
            getOptionLabel={(option: any) =>
                typeof option === 'string' ? option : option.text || ""
            }
            options={autocompleteOptions}
            inputValue={inputValue}
            onInputChange={async (_, searchText: string) => {
                onInputValueChanged(searchText);
                await debouncedFetchAutoCompleteData(searchText, setAutocompleteOptions);
            }}
            renderInput={(params) => {
                return <TextField {...params} label={label} size="small" variant="outlined"/>;
            }}
            renderOption={(props: React.HTMLAttributes<HTMLLIElement> & { key: any }, option: AutoCompleteOption) => {
                const {key, ...optionProps} = props;
                switch (option.type) {
                    case AutoCompleteTypes.NoPermissions: {
                        return (
                            <li key={key} {...optionProps} unselectable="on" onClick={(e) => {
                                requestPeopleScopes();
                                e.preventDefault();
                            }}>
                                <AdminPanelSettingsIcon color="primary"/>
                                <span> CLICK to enable autocomplete of your Google Contacts</span>
                            </li>
                        );
                    }
                    case AutoCompleteTypes.NoResults: {
                        return (
                            <li key={key} {...optionProps} style={{cursor: "default"}} onClick={(e) => {
                                e.preventDefault();
                            }}>
                                Email address not found in contacts
                            </li>
                        );
                    }
                    default: {
                        return (
                            <li key={key} {...optionProps}>
                                {option.text}
                            </li>
                        );
                    }
                }
            }}
        />
    );
}
