import {asLuxonDate} from "../utils/date";

declare global {
    interface Window {
        intercomAppId: string;
    }

    function Intercom(type: string, data: object | string, context?: object): void;
}

const CHROME_EXTENSION_ID = "dniglbfmfllnnkbjilhjignncgcadojn";

function intercomAvailable() {
    return window.intercomAppId !== "" && window.intercomAppId !== "None" && window.Intercom;
}

function checkExtension() {
    try {
        chrome.runtime.sendMessage(CHROME_EXTENSION_ID, "version", (response) => {
            if (response) {
                window.Intercom("update", {"chrome_extension": response.version});
            }
        });
    } catch {
        /* do nothing */
    }
}

export function intercomInit(
    userId: string,
    intercomUserId: string,
    createdOn: string,
    name: string,
    email: string,
    isAccountOwner: boolean,
    isTrial: boolean,
    trialExpiration: string,
    newUI: boolean,
    forceNewUI: boolean,
) {
    if (intercomAvailable()) {
        window.Intercom("boot", {
            app_id: window.intercomAppId,
            user_id: userId,
            user_hash: intercomUserId,
            created_at: asLuxonDate(createdOn).toUnixInteger(),
            name: name,
            email: email,
            "account_owner": isAccountOwner,
            "trial": isTrial,
            "trial_exp_at": trialExpiration ? asLuxonDate(trialExpiration).toUnixInteger() : undefined,
            "new_ui": newUI,
            "force_new_ui": forceNewUI,
        });

        checkExtension();
    }
}

export function intercomSendEvent(name: string, context?: object) {
    if (intercomAvailable()) {
        window.Intercom("trackEvent", name, context);
    }
}
