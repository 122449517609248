import * as React from "react";
import {Box, Chip, Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {createPortalSession, Subscription} from "../../../clients/teamcal-api";
import {useNotifications} from "@toolpad/core";
import {showNonResourceAPIErrorNotification} from "../../../clients/teamcal-api-notifications";
import {asLuxonDate} from "../../../utils/date";
import {intercomSendEvent} from "../../../clients/intercom-api";

export function ManageSubscription({subscription}: { subscription: Subscription }) {
    const notifications = useNotifications();
    const expiresOn = asLuxonDate(subscription.expiresOn);

    const handleManage = async () => {
        try {
            const session = await createPortalSession();
            intercomSendEvent("account-subscription-manage");
            window.location.replace(session.stripeUrl);
        } catch (error) {
            showNonResourceAPIErrorNotification(error, notifications);
        }
    }

    return (
        <Box>
            <Stack direction="column" spacing={2} sx={{display: "flex", alignItems: "flex-start"}}>
                <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>Current Subscription</Typography>
                <Stack direction="row" spacing={2} sx={{display: "flex", alignItems: "center"}}>
                    <Typography>Plan: {subscription.plan.toUpperCase()} ({subscription.interval === 1 ? "Monthly" : "Yearly"})</Typography>
                    {subscription.autoRenew ? (
                        <Chip size="small" color="success" label="Active"/>
                    ) : (
                        <Chip size="small" color="warning" label={`Ends on ${expiresOn.toFormat("DDD")}`}/>
                    )}
                </Stack>
                <Button variant="contained" onClick={handleManage}>
                    Manage Subscription & Billing
                </Button>
                <Typography variant="body2">Click &quot;Manage&quot; to upgrade, change subscription, billing info, payment method
                    and see invoices.</Typography>
            </Stack>
        </Box>
    );
}
