import {ShowNotification} from "@toolpad/core/useNotifications/useNotifications";
import {MbscScheduleResource} from "../routes/schedule/utils/utils";
import {TeamCalAPIError, TeamCalHttpError} from "./teamcal-api";

type Notifications = {
    show: ShowNotification
}

const DefaultAutoHideDuration = 3 * 1000;
const DefaultButtonAutoHideDuration = 7 * 1000;

function showCalendarNotFoundNotification(resource: MbscScheduleResource, notifications: Notifications) {
    notifications.show(
        `Calendar "${resource.name}" does not exist.`,
        {
            severity: 'error',
            autoHideDuration: DefaultAutoHideDuration,
        }
    );
}

function showCalendarAlreadyAddedNotification(resource: MbscScheduleResource, notifications: Notifications) {
    notifications.show(`Calendar "${resource.name}" already added.`, {
        severity: 'error',
        autoHideDuration: DefaultAutoHideDuration,
    });
}

function showCalendarReadOnlyNotification(resource: MbscScheduleResource, notifications: Notifications) {
    const handleActionClicked = () => {
        window.open('https://support.google.com/calendar/answer/37082#existing', '_blank');
    }

    notifications.show(`Calendar "${resource.name}" is read only. Ask owner to give you write permissions.`, {
        severity: 'error',
        autoHideDuration: DefaultButtonAutoHideDuration,
        actionText: "Help",
        onAction: handleActionClicked,
    });
}

function showCalendarOutdatedNotification(resource: MbscScheduleResource, notifications: Notifications, onActionClicked: () => void) {
    notifications.show(`Events of calendar "${resource.name}" are outdated. Refresh schedule to see newest events.`, {
        severity: 'error',
        autoHideDuration: DefaultButtonAutoHideDuration,
        actionText: "Refresh",
        onAction: onActionClicked,
    });
}

function showRowOrderChangedNotification(notifications: Notifications) {
    const handleActionClicked = () => {
        /* Refresh the page */
        window.location.reload();
    }

    notifications.show("Another user has changed the row order. Reload the schedule and try again.", {
        severity: 'error',
        autoHideDuration: DefaultButtonAutoHideDuration,
        actionText: "Reload",
        onAction: handleActionClicked,
    });
}

function showAuthCredentialsInvalidNotification(resource: MbscScheduleResource, notifications: Notifications) {
    const handleActionClicked = () => {
        /* Force re-login to same page */
        const currentLocation = window.location.pathname;
        window.location.replace(`/login?force=true&redirect=${encodeURIComponent(currentLocation)}`);
    }

    notifications.show(`Google Credentials to access calendars are invalid. Re-Login to TeamCal and try again.`, {
        key: 'invalid-login',
        severity: 'error',
        autoHideDuration: DefaultButtonAutoHideDuration,
        actionText: "Re-Login",
        onAction: handleActionClicked,
    });
}

function showEventNotMoveableNotification(notifications: Notifications) {
    notifications.show(
        "Only the organizer can move events with invited attendees. Recurring events cannot be moved.",
        {
            severity: 'error',
            autoHideDuration: DefaultAutoHideDuration,
        }
    );
}

function showModifyDisabledNotification(notifications: Notifications) {
    notifications.show(
        "Your TeamCal account administrator disabled modifying calendar events.",
        {
            severity: 'error',
            autoHideDuration: DefaultAutoHideDuration,
        }
    );
}

function showUpgradeNeededNotification(notifications: Notifications, maxRows: string, onActionClicked: () => void) {
    notifications.show(`Your subscription supports ${maxRows} calendars. You exceeded this limit. Please upgrade your account to add more.`, {
        severity: 'error',
        autoHideDuration: DefaultButtonAutoHideDuration,
        actionText: "Upgrade",
        onAction: onActionClicked,
    });
}

function showCalendarAPIOperationFailedNotification(notifications: Notifications) {
    notifications.show(
        "Google Calendar API operation failed. Try again later.",
        {
            severity: 'error',
            autoHideDuration: DefaultAutoHideDuration,
        }
    );
}

function showGSuiteAccountRequired(notifications: Notifications) {
    notifications.show(
        "G-Suite account is required to enable SSO. Personal GMail accounts are not supported.",
        {
            severity: 'error',
            autoHideDuration: DefaultAutoHideDuration,
        }
    )
}

function showAPIOperationFailedNotification(errorCode: string, notifications: Notifications) {
    notifications.show(
        `API Operation failed: ${errorCode}`,
        {
            severity: 'error',
            autoHideDuration: DefaultAutoHideDuration,
        }
    );
}

function showUnknownOperationFailedNotification(error: any, notifications: Notifications) {
    notifications.show(
        `Unknown error: ${error}`,
        {
            severity: 'error',
            autoHideDuration: DefaultAutoHideDuration,
        }
    );
}

export function showAPIErrorNotification(
    error: any,
    resource: MbscScheduleResource,
    notifications: Notifications,
    onCalendarRefreshAction: () => void,
    onCalendarsExceededAction: () => void,
) {
    if (error instanceof TeamCalAPIError && error.details) {
        switch (error.details.code) {
            case "CalendarNotFound": {
                showCalendarNotFoundNotification(resource, notifications);
                return;
            }
            case "ScheduleRowAlreadyAdded": {
                showCalendarAlreadyAddedNotification(resource, notifications);
                return;
            }
            case "ScheduleRowCountExceeded": {
                showUpgradeNeededNotification(notifications, error.details.description, onCalendarsExceededAction);
                return;
            }
            case "CalendarNotWriteable": {
                showCalendarReadOnlyNotification(resource, notifications);
                return;
            }
            case "CalendarPermissionDenied": {
                // e.g. happens when event which is moved doesn't exist anymore
                showCalendarOutdatedNotification(resource, notifications, onCalendarRefreshAction);
                return;
            }
            case "CalendarCredentialsInvalid": {
                showAuthCredentialsInvalidNotification(resource, notifications);
                return;
            }
            default: {
                showNonResourceAPIErrorNotification(error, notifications);
                return;
            }
        }
    }

    showUnknownOperationFailedNotification(error, notifications);
}

export function showNonResourceAPIErrorNotification(
    error: any,
    notifications: Notifications,
) {
    if (error instanceof TeamCalAPIError) {
        switch (error.details.code) {
            case "CalendarError": {
                showCalendarAPIOperationFailedNotification(notifications);
                return;
            }
            case "CalendarNotMoveableError": {
                showEventNotMoveableNotification(notifications);
                return;
            }
            case "ScheduleOperationNotAllowed": {
                showModifyDisabledNotification(notifications);
                return;
            }
            case "ScheduleRowOrderChanged": {
                showRowOrderChangedNotification(notifications);
                return;
            }
            case "GSuiteAccountRequired": {
                showGSuiteAccountRequired(notifications);
                return;
            }
            default: {
                showAPIOperationFailedNotification(error.details.code, notifications);
                return;
            }
        }
    } else if (error instanceof TeamCalHttpError) {
        showUnknownOperationFailedNotification(error.httpStatus, notifications);
    } else {
        showUnknownOperationFailedNotification(error, notifications);
    }
}
