import * as React from "react";
import {useCallback} from "react";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Tab,
    Tabs,
    Tooltip
} from "@mui/material";
import {DialogProps, useNotifications} from "@toolpad/core";
import Button from "@mui/material/Button";
import LaunchIcon from "@mui/icons-material/Launch";
import {TabPanel} from "../../../utils/tab-panel";
import {GeneralEventEdit} from "./tab-general/general-event-edit";
import {isEventReadOnly, MbscScheduleEvent, MbscScheduleResource} from "../utils/utils";
import {DescriptionEventEdit} from "./tab-description/description-event-edit";
import {GuestsEventEdit} from "./tab-guests/guests-event-edit";
import {EventChangedPayload, EventChangedType} from "../utils/payload-types";
import {LocaleCtx} from "../locale-provider";

export type EventEditPayload = {
    event: MbscScheduleEvent;
    resourceId?: string;
    oldResourceId?: string;
    availableResources: MbscScheduleResource[];
    allowMove: boolean;
    allowDelete: boolean;
    locale: LocaleCtx,
};

export type SetEventProperty = (prop: string, val: any) => void

export function EditEventDialog({payload, open, onClose}: DialogProps<EventEditPayload, EventChangedPayload | null>) {
    const notifications = useNotifications();
    const [tabValue, setTabValue] = React.useState(0);
    const [event, setEvent] = React.useState<MbscScheduleEvent>(payload.event);
    const [changedEventProperties, setChangedEventProperties] = React.useState(new Set<string>());
    const readOnlyEvent = isEventReadOnly(payload.event);

    const handleTabChange = (_e: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const setEventProperty = useCallback((prop: string, val: any) => {
        // Queue State Changes (for example changing time might call multiple times)
        setEvent(event => {
            return {...event, [prop]: val}
        });
        setChangedEventProperties(changedEventProperties => new Set(changedEventProperties.add(prop)));
    }, []);

    const handleCancel = async () => {
        await onClose(null);
    };

    const handleSave = async () => {
        if (!event.resource) {
            notifications.show(`Select a row to create an event`, {
                severity: 'error',
                autoHideDuration: 3 * 1000,
            });
            return;
        }

        await onClose({
            type: payload.event.isNew ? EventChangedType.Create : EventChangedType.Update,
            event: event,
            oldEvent: payload.event,
            changedEventProperties: Array.from(changedEventProperties),
            resourceId: event.resource as string,
            oldResourceId: payload.resourceId!,
        });
    };

    const handleDelete = async () => {
        await onClose({
            type: EventChangedType.Delete,
            event: event,
            changedEventProperties: [],
            resourceId: event.resource as string,
            oldResourceId: payload.resourceId!,
        });
    }

    const dialogTitlePrefix = () => {
        if (payload.event.isNew) {
            return "Add";
        } else if (readOnlyEvent) {
            return "";
        } else {
            return "Edit";
        }
    }

    const dialogTitlePostfix = () => {
        const texts = [];

        if (readOnlyEvent) {
            texts.push("Read-Only");
        }

        if (payload.event.outOfOffice) {
            texts.push("Out of Office");
        }

        return texts.length > 0 ? ` (${texts.join(", ")})` : "";
    }

    return (
        <Dialog fullWidth sx={{minHeight: 528}} open={open} onClose={handleCancel}>
            <DialogTitle bgcolor="primary.main" sx={{color: "primary.contrastText"}}>
                <Stack direction="row">
                    <Box sx={{flex: 1, display: "flex", alignItems: "center"}}>
                        {dialogTitlePrefix()} Event {dialogTitlePostfix()}
                    </Box>
                    {event.link &&
                        <Tooltip title="Open event in Google Calendar">
                            <IconButton
                                aria-label="Open in Google Calendar"
                                target="_blank"
                                rel="noopener"
                                href={event.link}
                            >
                                <LaunchIcon sx={{color: "primary.contrastText"}}/>
                            </IconButton>
                        </Tooltip>
                    }
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label="General"/>
                        {!event.outOfOffice &&
                            <Tab label="Description"/>
                        }
                        {!event.outOfOffice && !readOnlyEvent &&
                            <Tab label="Guests"/>
                        }
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <GeneralEventEdit locale={payload.locale}
                                      allowMove={payload.allowMove}
                                      event={event}
                                      resources={payload.availableResources}
                                      setEventProperty={setEventProperty}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <DescriptionEventEdit event={event} setEventProperty={setEventProperty}/>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <GuestsEventEdit event={event} setEventProperty={setEventProperty}/>
                </TabPanel>
            </DialogContent>
            <DialogActions>
                {readOnlyEvent ? (
                    <Box sx={{width: "100%", pl: 2, pr: 2, pb: 2}} display="flex">
                        <span style={{flexGrow: 1}}></span>
                        <Button onClick={handleCancel}>Close</Button>
                    </Box>
                ) : (
                    <Box sx={{width: "100%", pl: 2, pr: 2, pb: 2}} display="flex">
                        {!payload.event.isNew && payload.allowDelete &&
                            <Button onClick={handleDelete} color="secondary">Delete</Button>
                        }
                        <span style={{flexGrow: 1}}></span>
                        <Button onClick={handleCancel} color="secondary">Cancel</Button>
                        <Button onClick={handleSave} variant="contained" sx={{ml: 1}}>Save</Button>
                    </Box>
                )}
            </DialogActions>
        </Dialog>
    );
}
