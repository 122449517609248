import * as React from "react";
import {ReactElement} from "react";
import {useAuth} from "../../auth/auth-provider";
import {Navigate, useLocation} from "react-router";

export function RequireAuth({children}: { children: ReactElement }) {
    const {authContext} = useAuth();
    const location = useLocation();

    if (authContext && authContext.isAuthenticated) {
        return children;
    } else if (!authContext || authContext.isAuthenticated === undefined) {
        return <div>Loading...</div>;
    } else {
        return <Navigate to="/signup" state={{"pathname": location.pathname}}/>;
    }
}
