import * as React from "react";
import {useNotifications} from "@toolpad/core";
import {EventcalendarBase} from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";
import {Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip} from "@mui/material";
import {ExcelFileIcon, JpgFileIcon, PdfFileIcon, PngFileIcon} from "../utils/file-icons";
import DownloadIcon from "@mui/icons-material/Download";
import {useSchedule} from "../../schedule-provider";
import {Export2Jpeg, Export2Png} from "../export/image";
import {Export2Pdf} from "../export/pdf";
import {Export2Excel} from "../export/excel";
import {useLocale} from "../../locale-provider";

export function ExportActions({instance}: { instance: EventcalendarBase | undefined }) {
    const locale = useLocale();
    const schedule = useSchedule();
    const notifications = useNotifications();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const fileName = schedule.data.name || "Untitled Schedule";

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const showErrorNotification = () => {
        notifications.show(`Export failed`, {
            severity: "error",
            autoHideDuration: 3000,
        });
    }

    const showExportNotSupported = () => {
        notifications.show(`Export not supported for "HOURLY" zoom. Switch zoom level to export.`, {
            severity: "warning",
            autoHideDuration: 3000,
        });
    }

    const isSupported = () => {
        if (schedule.data.viewZoom === "hour" && schedule.data.viewTimeframe !== "D1") {
            showExportNotSupported();
            return false;
        } else {
            return true;
        }
    }

    const handlePngExport = () => {
        handleClose();

        if (!isSupported()) {
            return;
        }

        const n = notifications.show("Generating PNG...");
        setTimeout(async () => {
            try {
                await Export2Png(instance!, fileName);
            } catch (e) {
                showErrorNotification(e);
            } finally {
                notifications.close(n);
            }
        }, 150);
    }

    const handleJpgExport = () => {
        handleClose();

        if (!isSupported()) {
            return;
        }

        const n = notifications.show("Generating JPEG...");
        setTimeout(async () => {
            try {
                await Export2Jpeg(instance!, fileName);
            } catch (e) {
                showErrorNotification(e);
            } finally {
                notifications.close(n);
            }
        }, 150);
    }

    const handlePdfExport = () => {
        handleClose();

        if (!isSupported()) {
            return;
        }

        const n = notifications.show("Generating PDF...");
        setTimeout(async () => {
            try {
                await Export2Pdf(instance!, fileName);
            } catch (e) {
                showErrorNotification(e);
            } finally {
                notifications.close(n);
            }
        }, 150);
    }

    const handleExcelExport = () => {
        handleClose();

        if (!isSupported()) {
            return;
        }

        const n = notifications.show("Generating Excel...");
        setTimeout(async () => {
            try {
                await Export2Excel(locale, instance!, fileName);
            } catch (e) {
                showErrorNotification(e);
            } finally {
                notifications.close(n);
            }
        }, 150);
    }

    return (
        <Box>
            <Tooltip title="Export Schedule">
                <IconButton  color="secondary" onClick={handleClick}>
                    <DownloadIcon/>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handlePngExport()}>
                    <ListItemIcon>
                        <PngFileIcon/>
                    </ListItemIcon>
                    <ListItemText>PNG Image</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleJpgExport()}>
                    <ListItemIcon>
                        <JpgFileIcon/>
                    </ListItemIcon>
                    <ListItemText>JPEG Image</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handlePdfExport()}>
                    <ListItemIcon>
                        <PdfFileIcon/>
                    </ListItemIcon>
                    <ListItemText>PDF Document</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleExcelExport()}>
                    <ListItemIcon>
                        <ExcelFileIcon/>
                    </ListItemIcon>
                    <ListItemText>Excel Document</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
}
