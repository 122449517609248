import * as React from "react";
import {useRef, useState} from "react";
import Popover from "@mui/material/Popover";
import {buildFromScheduleEvent, MbscScheduleEvent, MbscScheduleResource} from "../../utils/utils";
import {
    Box, CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText, Stack,
    TextField,
    Tooltip, Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import {getScheduleEvents} from "../../../../clients/teamcal-api";
import {DateTime} from "luxon";
import {batchPromiseAll} from "../../../../utils/promise";
import {showNonResourceAPIErrorNotification} from "../../../../clients/teamcal-api-notifications";
import {useNotifications} from "@toolpad/core";
import {asLuxonDate} from "../../../../utils/date";
import {useLocale} from "../../locale-provider";

const SEARCH_API_CALLS_CONCURRENCY = 5;

type SearchResult = {
    resource: MbscScheduleResource,
    event: MbscScheduleEvent,
}

export function SearchEvents({scheduleId, resources, onEventClicked, onJumpToEvent,}: {
    scheduleId: string,
    resources: MbscScheduleResource[],
    onEventClicked: (event: MbscScheduleEvent) => void,
    onJumpToEvent: (event: MbscScheduleEvent) => void,
}) {
    const locale = useLocale();
    const notifications = useNotifications();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const searchBoxRef = useRef<HTMLInputElement | null>(null);
    const open = Boolean(anchorEl);

    const handleSearchOpenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);

        setTimeout(() => {
            if (searchBoxRef.current) {
                searchBoxRef.current.focus();
            }
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEventClicked = (event: MbscScheduleEvent) => {
        handleClose();
        onEventClicked(event);
    }

    const handleJumpToEvent = (event: MbscScheduleEvent) => {
        handleClose();
        onJumpToEvent(event);
    }

    const handleSearch = async () => {
        setSearchResults([]);
        setIsSearching(true);
        const from = DateTime.now().minus({year: 5}).toJSDate(); // Only go back 5 years
        const to = DateTime.now().plus({year: 3}).toJSDate(); // Only look forward for 3 years
        const limit = 250;

        try {
            const responses = await batchPromiseAll(
                resources.map(async resource => {
                    try {
                        return (await getScheduleEvents(
                            scheduleId,
                            resource.id as string,
                            from, to,
                            searchText,
                            limit
                        )).map(
                            event => ({
                                resource: resource,
                                event: buildFromScheduleEvent(event, resource),
                            })
                        );
                    } catch (error) {
                        showNonResourceAPIErrorNotification(error, notifications);
                        return [];
                    }
                }),
                SEARCH_API_CALLS_CONCURRENCY
            );

            const sortedResults = responses.flat().sort((r1, r2) => {
                return (r2.event.start as string).localeCompare(r1.event.start as string);
            });
            setSearchResults(sortedResults);
        } finally {
            setIsSearching(false);
        }
    }

    return (
        <Box>
            <Tooltip title="Search for events">
                <IconButton
                    color="secondary"
                    aria-label="Filter rows"
                    onClick={handleSearchOpenClick}
                >
                    <SearchIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={{p: 2, width: 430}}>
                    <Stack direction="row" spacing={1}>
                        <TextField
                            autoComplete="off"
                            variant="outlined"
                            aria-label="Search text"
                            label="Search text"
                            value={searchText}
                            size="small"
                            fullWidth
                            inputRef={searchBoxRef}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchText(e.target.value);
                            }}
                            onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === "Enter" || e.key === "NumpadEnter") {
                                    await handleSearch();
                                }
                            }}
                        />
                        <Box width={140}>
                            <Button onClick={handleSearch} disabled={isSearching}>
                                Search {isSearching && <CircularProgress sx={{ml: 2}} size={12}/>}
                            </Button>
                        </Box>
                    </Stack>
                    {searchResults.length > 0 &&
                        <List sx={{width: "100%", maxHeight: 350, overflowY: "auto"}}>
                            {searchResults.map(result => {
                                const start = asLuxonDate(result.event.start).setZone(result.event.allDay ? "UTC": locale.uiTimezone);
                                const end = asLuxonDate(result.event.end).setZone(result.event.allDay ? "UTC": locale.uiTimezone);

                                return (
                                    <ListItem
                                        key={result.event.id}
                                        secondaryAction={
                                            <Button
                                                color="secondary"
                                                onClick={() => handleJumpToEvent(result.event)}>
                                                Jump To
                                            </Button>
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton onClick={() => handleEventClicked(result.event)}>
                                            <ListItemText>
                                                <Stack direction="column" spacing={0.5}>
                                                    <Typography variant="body1">{result.event.title}</Typography>
                                                    {result.event.allDay ? (
                                                        <Typography variant="caption">
                                                            {start.toFormat(locale.luxonLocale.dateFormat)} - {end.minus({days: 1}).toFormat(locale.luxonLocale.dateFormat)}
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="caption">
                                                            {start.toFormat(locale.luxonLocale.dateTimeFormat)} - {end.toFormat(locale.luxonLocale.dateTimeFormat)}
                                                        </Typography>
                                                    )}
                                                    <Typography variant="caption">{result.resource.name}</Typography>
                                                </Stack>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    }
                </Box>
            </Popover>
        </Box>
    );
}
