import createClient, {Middleware} from "openapi-fetch";
import {components, paths} from "./teamcal-api-schema";
import {getCookie} from "../utils/cookie";
import {retryFetch} from "./retry-fetch";

let crsfToken: string | undefined
const crsfTokenMiddleware: Middleware = {
    async onRequest({request}) {
        if (!crsfToken) {
            crsfToken = getCookie("csrftoken");
        }

        if (crsfToken) {
            request.headers.set("X-CSRFToken", crsfToken);
        }

        return request;
    },
};

const client = createClient<paths>({credentials: "same-origin", fetch: retryFetch});
client.use(crsfTokenMiddleware);

export class AuthenticationRequiredError extends Error {
}

export type ErrorResponse = components["schemas"]["ErrorResponse"];

export class TeamCalHttpError extends Error {
    httpStatus: number

    constructor(status: number) {
        super();
        this.httpStatus = status;
    }
}

export class TeamCalAPIError extends TeamCalHttpError {
    details: ErrorResponse

    constructor(status: number, error: ErrorResponse) {
        super(status);
        this.details = error;
    }
}

function handleError(status: number, error?: ErrorResponse): never {
    if (error && (error as ErrorResponse).code !== undefined) {
        throw new TeamCalAPIError(status, error);
    } else if (status === 403) {
        crsfToken = undefined;
        throw new AuthenticationRequiredError();
    } else {
        throw new TeamCalHttpError(status);
    }
}

export type Account = components["schemas"]["Account"];

export async function getAccount(): Promise<Account> {
    const {data, response} = await client.GET("/api/account/");

    if (data) {
        return data;
    } else {
        handleError(response.status);
    }
}

export async function deleteAccount() {
    const {error, response} = await client.DELETE("/api/account/delete");

    if (response.status >= 400) {
        handleError(response.status, error);
    }
}

export type UpdateUserSettings = components["schemas"]["UserSettings"]

export async function updateUserSettings(settings: UpdateUserSettings): Promise<UpdateUserSettings> {
    const {data, response} = await client.PUT("/api/account/settings/", {
        body: settings,
    });

    if (data) {
        return data;
    } else {
        handleError(response.status);
    }
}

export type UpdateAccountRestrictions = components["schemas"]["AccountRestrictions"]

export async function updateAccountRestrictions(restrictions: UpdateAccountRestrictions): Promise<UpdateAccountRestrictions> {
    const {data, response} = await client.PUT("/api/account/restrictions/", {
        body: restrictions,
    });

    if (data) {
        return data;
    } else {
        handleError(response.status);
    }
}

export type ScheduleInfo = components["schemas"]["ScheduleInfo"];
export type ViewTimeframeEnum = components["schemas"]["ViewTimeframeEnum"];
export type ViewZoomEnum = components["schemas"]["ViewZoomEnum"];
export type ViewModeEnum = components["schemas"]["ViewModeEnum"];
export type AllDaySettingsEnum = components["schemas"]["AllDaySettingsEnum"];
export type OutOfOfficeSettingsEnum = components["schemas"]["OutOfOfficeSettingsEnum"];

export async function getSchedules(): Promise<ScheduleInfo[]> {
    const {data, response} = await client.GET("/api/schedules/");

    if (data) {
        return data;
    } else {
        handleError(response.status);
    }
}

export type Schedule = components["schemas"]["Schedule"];
export type CreateSchedule = components["schemas"]["CreateSchedule"];

export async function createSchedule(schedule: CreateSchedule): Promise<Schedule> {
    const {data, error, response} = await client.POST("/api/schedules/", {
        body: schedule,
    });

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export type UpdateSchedule = components["schemas"]["UpdateSchedule"];

export async function updateSchedule(scheduleId: string, schedule: UpdateSchedule) {
    const {error, response} = await client.PUT("/api/schedules/{schedule_uuid}/", {
        params: {
            path: {schedule_uuid: scheduleId},
        },
        body: schedule,
    });

    if (response.status >= 400) {
        handleError(response.status, error);
    }
}

export async function deleteSchedule(scheduleId: string) {
    const {error, response} = await client.DELETE("/api/schedules/{schedule_uuid}/", {
        params: {
            path: {schedule_uuid: scheduleId},
        },
    });

    if (response.status >= 400) {
        handleError(response.status, error);
    }
}

export type ScheduleRow = components["schemas"]["ScheduleRow"]

export async function getSchedule(scheduleId: string): Promise<Schedule> {
    const {data, error, response} = await client.GET("/api/schedules/{schedule_uuid}/", {
        params: {
            path: {
                schedule_uuid: scheduleId,
            },
        },
    });

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export type AddRow = components["schemas"]["AddRow"];

export async function addScheduleRow(scheduleId: string, row: AddRow): Promise<ScheduleRow> {
    const {data, error, response} = await client.POST("/api/schedules/{schedule_uuid}/rows/", {
        params: {
            path: {
                schedule_uuid: scheduleId,
            },
        },
        body: row,
    });

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export type UpdateRow = components["schemas"]["UpdateRow"];

export async function updateScheduleRow(scheduleId: string, rowId: string, row: UpdateRow): Promise<ScheduleRow> {
    const {data, error, response} = await client.PUT("/api/schedules/{schedule_uuid}/rows/{row_uuid}/", {
        params: {
            path: {
                schedule_uuid: scheduleId,
                row_uuid: rowId,
            },
        },
        body: row,
    });

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export async function deleteScheduleRow(scheduleId: string, rowId: string): Promise<void> {
    const {error, response} = await client.DELETE("/api/schedules/{schedule_uuid}/rows/{row_uuid}/", {
        params: {
            path: {
                schedule_uuid: scheduleId,
                row_uuid: rowId,
            },
        },
    });

    if (response.status >= 400) {
        handleError(response.status, error);
    }
}

export type ScheduleEvent = components["schemas"]["ScheduleEvent"];

export async function getScheduleEvents(scheduleId: string, rowId: string, from?: Date, to?: Date, text?: string, limit?: number): Promise<ScheduleEvent[]> {
    const {data, error, response} = await client.GET("/api/schedules/{schedule_uuid}/rows/{row_uuid}/events/", {
        params: {
            path: {
                schedule_uuid: scheduleId,
                row_uuid: rowId,
            },
            query: {
                from: from ? from.toJSON() || undefined : undefined,
                to: to ? to.toJSON() || undefined : undefined,
                text: text,
                limit: limit,
            },
        },
    });

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export type AddScheduleRowEvent = components["schemas"]["AddScheduleRowEvent"];

export async function createScheduleEvent(scheduleId: string, rowId: string, event: AddScheduleRowEvent): Promise<ScheduleEvent> {
    const {data, error, response} = await client.POST("/api/schedules/{schedule_uuid}/rows/{row_uuid}/events/", {
        params: {
            path: {
                schedule_uuid: scheduleId,
                row_uuid: rowId,
            }
        },
        body: event,
    });

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export type UpdateScheduleRowEvent = components["schemas"]["UpdateScheduleRowEvent"];

export async function updateScheduleEvent(scheduleId: string, rowId: string, eventId: string, event: UpdateScheduleRowEvent): Promise<ScheduleEvent> {
    const {
        data,
        error,
        response
    } = await client.PUT("/api/schedules/{schedule_uuid}/rows/{row_uuid}/events/{event_id}/", {
        params: {
            path: {
                schedule_uuid: scheduleId,
                row_uuid: rowId,
                event_id: eventId
            }
        },
        body: event,
    });

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export async function deleteScheduleEvent(scheduleId: string, rowId: string, eventId: string) {
    const {error, response} = await client.DELETE("/api/schedules/{schedule_uuid}/rows/{row_uuid}/events/{event_id}/", {
        params: {
            path: {
                schedule_uuid: scheduleId,
                row_uuid: rowId,
                event_id: eventId
            }
        },
    });

    if (response.status >= 400) {
        handleError(response.status, error);
    }
}


export type ScheduleEventAttendee = components["schemas"]["ScheduleEventAttendee"];

export type CalendarResult = components["schemas"]["CalendarResult"];

export async function searchCalendars(searchText: string, sources: string, excludeInternals: boolean, limit: number): Promise<CalendarResult[]> {
    const {data, error, response} = await client.GET("/api/calendars/", {
        params: {
            query: {
                q: searchText,
                sources: sources,
                excludeInternals: excludeInternals,
                max: limit,
            },
        },
    });

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export async function setNotification(notification: string) {
    const {response} = await client.PUT("/api/account/notifications/", {
        body: {
            notification: notification,
        },
    });

    if (response.status >= 400) {
        handleError(response.status);
    }
}

export type SharedScheduleToken = components["schemas"]["SharedScheduleTokenResponse"];

export async function createSharedScheduleToken(scheduleId: string): Promise<SharedScheduleToken> {
    const {data, error, response} = await client.POST("/api/schedules/{schedule_uuid}/share/", {
        params: {
            path: {
                schedule_uuid: scheduleId,
            }
        },
    });

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export async function getSharedScheduleToken(scheduleId: string): Promise<SharedScheduleToken | undefined> {
    const {data, error, response} = await client.GET("/api/schedules/{schedule_uuid}/share/", {
        params: {
            path: {
                schedule_uuid: scheduleId,
            }
        },
    });

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export type UpdateSharedScheduleToken = components["schemas"]["UpdateSharedScheduleToken"];

export async function updateSharedScheduleToken(scheduleId: string, tokenId: string, token: UpdateSharedScheduleToken) {
    const {error, response} = await client.PUT("/api/schedules/{schedule_uuid}/share/{token}/", {
        params: {
            path: {
                schedule_uuid: scheduleId,
                token: tokenId,
            }
        },
        body: token
    });

    if (response.status >= 400) {
        handleError(response.status, error);
    }
}

export async function deleteSharedScheduleToken(scheduleId: string, tokenId: string) {
    const {error, response} = await client.DELETE("/api/schedules/{schedule_uuid}/share/{token}/", {
        params: {
            path: {
                schedule_uuid: scheduleId,
                token: tokenId,
            }
        },
    });

    if (response.status >= 400) {
        handleError(response.status, error);
    }
}

export async function inviteResource(scheduleId: string, rowId: string, eventId: string, attendeeEmails: string[]) {
    const {
        error,
        response
    } = await client.POST("/api/schedules/{schedule_uuid}/rows/{row_uuid}/events/{event_id}/attendees/", {
        params: {
            path: {
                schedule_uuid: scheduleId,
                row_uuid: rowId,
                event_id: eventId,
            }
        },
        body: {
            attendees: attendeeEmails,
        }
    });

    if (response.status >= 400) {
        handleError(response.status, error);
    }
}

export type User = components["schemas"]["UserResponse"];

export async function getUsers(): Promise<User[]> {
    const {data, error, response} = await client.GET("/api/account/users/");

    if (data) {
        return data.users;
    } else {
        handleError(response.status, error);
    }
}

export type InviteUser = components["schemas"]["InviteUserResponse"];

export async function inviteUser(): Promise<InviteUser> {
    const {data, error, response} = await client.POST("/api/account/users/");

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export async function deleteUser(email: string) {
    const {error, response} = await client.DELETE("/api/account/users/{email}/", {
        params: {
            path: {
                email: email,
            }
        }
    });

    if (response.status >= 400) {
        handleError(response.status, error);
    }
}

export async function configureSSO(enable: boolean) {
    const {error, response} = await client.PUT("/api/account/sso/", {
        body: {
            enable: enable,
        }
    });

    if (response.status >= 400) {
        handleError(response.status, error);
    }
}

export type PortalSession = components["schemas"]["PortalResponse"];

export async function createPortalSession(): Promise<PortalSession> {
    const {data, error, response} = await client.POST("/api/account/portal/");

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export type PaymentSession = components["schemas"]["PaymentResponse"];

export async function createPaymentSession(plan: string, interval: number): Promise<PaymentSession> {
    const {data, error, response} = await client.POST("/api/account/payment/", {
        body: {
            plan: plan,
            interval: interval,
        }
    });

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export type Subscription = components["schemas"]["SubscriptionResponse"];

export async function getSubscription(): Promise<Subscription> {
    const {data, error, response} = await client.GET("/api/account/subscription/");

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export type SharedViewInfo = components["schemas"]["ScheduleSerializerResponse"];

export async function getSharedView(sharedToken: string): Promise<SharedViewInfo> {
    const {data, error, response} = await client.PUT("/api/sharing/schedules/", {
        body: {
            token: sharedToken,
        }
    });

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}

export type SharedScheduleEvent = components["schemas"]["ScheduleEventSerializerResponse"];

export async function getSharedScheduleEvents(sharedToken: string, rowId: string, from: Date, to: Date): Promise<SharedScheduleEvent[]> {
    const {data, error, response} = await client.PUT("/api/sharing/schedules/events/", {
        body: {
            token: sharedToken,
            rowId: rowId,
            start: from.toJSON(),
            end: to.toJSON(),
        },
    });

    if (data) {
        return data;
    } else {
        handleError(response.status, error);
    }
}
