import * as React from "react";
import {Box, Typography} from "@mui/material";
import {DateTime, Interval} from "luxon";
import {asLuxonDate} from "../../../../utils/date";
import {MbscScheduleEvent} from "../../utils/utils";
import {binarySearch} from "../../../../utils/search";

function createIntervals(
    baseInterval: Interval,
    workWeekStart: number,
    workWeekEnd: number,
    workDayStart: number,
    workDayEnd: number
) {
    const intervals = [];
    let currentDay = baseInterval.start!;
    const lastDay = baseInterval.end!;

    while (currentDay < lastDay) {
        const zeroBasedWeekday = currentDay.weekday % 7;
        if (zeroBasedWeekday >= workWeekStart && zeroBasedWeekday <= workWeekEnd) {
            const interval = Interval.fromDateTimes(
                workDayStart > 0 ? currentDay.plus({minutes: workDayStart}) : currentDay,
                workDayEnd < 24 * 60 ? currentDay.plus({minutes: workDayEnd}) : currentDay.plus({day: 1}),
            );

            intervals.push(interval);
        }

        currentDay = currentDay.plus({days: 1});
    }

    return intervals;
}


export type DurationSummary = {
    availableHours: number;
    scheduledHours: number;
}

export type CalculateProps = {
    scheduleEvents: MbscScheduleEvent[];
    viewStart: DateTime;
    viewEnd: DateTime;
    workWeekStart: number;
    workWeekEnd: number;
    workDayStart: number;
    workDayEnd: number;
    uiTimezone: string;
}

export function calculateEventDurations(props: CalculateProps) {
    const viewInterval = Interval.fromDateTimes(props.viewStart.startOf("day"), props.viewEnd.startOf("day"));
    const viewIntervals = createIntervals(viewInterval, props.workWeekStart, props.workWeekEnd, props.workDayStart, props.workDayEnd);
    const availableHours = viewIntervals.length > 0 ? viewIntervals[0].length("hours") * viewIntervals.length : 0;

    return props.scheduleEvents.reduce((result, event) => {
        const eventInterval = Interval.fromDateTimes(
            asLuxonDate(event.start).setZone(props.uiTimezone, {keepLocalTime: event.allDay}),
            asLuxonDate(event.end).setZone(props.uiTimezone, {keepLocalTime: event.allDay})
        );

        const from = binarySearch(viewIntervals, other => eventInterval.start!.toMillis() - other.end!.toMillis())[1];
        const to = binarySearch(viewIntervals, other => eventInterval.end!.toMillis() - other.start!.toMillis())[0];

        let scheduledHours = 0;
        for (const i of viewIntervals.slice(from, to + 1)) {
            if (eventInterval.overlaps(i)) {
                const intersection = i.intersection(eventInterval);
                if (intersection) {
                    scheduledHours += intersection.length("hours");
                }
            }
        }

        if (!result[event.resource as string]) {
            result[event.resource as string] = {
                availableHours: availableHours,
                scheduledHours: 0,
            };
        }

        result[event.resource as string].scheduledHours += scheduledHours;
        return result;
    }, {} as Record<string, DurationSummary>);
}

export function ResourceSidebar({availableHours, scheduledHours}: {
    availableHours?: number,
    scheduledHours?: number
}) {
    if (!availableHours || !scheduledHours) {
        return null;
    }

    const scheduledPct = scheduledHours / availableHours * 100;
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
        }}>
            <Typography variant="body1">{scheduledPct.toFixed(2)} %</Typography>
            <Typography
                variant="body2">{scheduledHours.toLocaleString(undefined, {maximumFractionDigits: 2})} h</Typography>
        </Box>
    );
}

export const MemoResourceSidebar = React.memo(ResourceSidebar);
