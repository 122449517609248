import * as React from "react";
import {
    Container,
    FormControl,
    Grid2 as Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    Typography
} from "@mui/material";
import {useAuth} from "../../../auth/auth-provider";
import {updateUserSettings} from "../../../clients/teamcal-api";
import {Timezones, WeekDays} from "../../../utils/date";
import {useNotifications} from "@toolpad/core";
import {showNonResourceAPIErrorNotification} from "../../../clients/teamcal-api-notifications";
import {DateTime} from "luxon";

export function TabProfile() {
    const {authContext, setAuthContext} = useAuth();
    const notifications = useNotifications();
    const account = authContext!.account!;
    const currentDate = DateTime.now();

    const updateSetting = async (setting: string, value: any) => {
        const currentContext = authContext!;

        try {
            // Update for immediate UI effect
            setAuthContext({
                ...authContext!,
                account: {
                    ...authContext!.account!,
                    userSettings: {
                        ...authContext!.account!.userSettings,
                        [setting]: value,
                    }
                }
            })

            account.userSettings = await updateUserSettings({[setting]: value});

            // Overwrite current context with response
            setAuthContext({...authContext})
        } catch (error) {
            // Reset view
            setAuthContext({...currentContext})

            showNonResourceAPIErrorNotification(error, notifications);
        }
    }

    const handleTimezone = async (e: SelectChangeEvent) => {
        await updateSetting("timezone", e.target.value);
    }

    const handleToggle24h = async () => {
        await updateSetting("time24h", !account.userSettings.time24h);
    }

    const handleDateOrder = async (e: SelectChangeEvent) => {
        await updateSetting("dateOrder", e.target.value);
    }

    const handleWeekStart = async (e: SelectChangeEvent) => {
        await updateSetting("weekStart", Number(e.target.value));
    }

    const handleToggleShowWeekNumbers = async () => {
        await updateSetting("showWeeks", !account.userSettings.showWeeks);
    }

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>Profile settings</Typography>
            <Container disableGutters maxWidth="sm">
                <Grid container spacing={1}>
                    <Grid size={4} display="flex" alignItems="center">
                        <Typography variant="body1">Google account</Typography>
                    </Grid>
                    <Grid size={8} display="flex" justifyContent="flex-end">
                        <Typography variant="body1" sx={{height: 38}}>{account.email}</Typography>
                    </Grid>
                    <Grid size={4} display="flex" alignItems="center">
                        <Typography variant="body1">Timezone</Typography>
                    </Grid>
                    <Grid size={8} display="flex" justifyContent="flex-end">
                        <FormControl variant="outlined" sx={{minWidth: 350}}>
                            <Select
                                variant="outlined"
                                size="small"
                                value={account.userSettings.timezone}
                                onChange={handleTimezone}
                            >
                                {Timezones.map(([zone, offset]) => {
                                    return (
                                        <MenuItem key={zone} value={zone}>({offset}) {zone}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid size={4} display="flex" alignItems="center">
                        <Typography variant="body1">Time 24h</Typography>
                    </Grid>
                    <Grid size={8} display="flex" justifyContent="flex-end">
                        <Switch checked={account.userSettings.time24h} onChange={handleToggle24h}/>
                    </Grid>
                    <Grid size={4} display="flex" alignItems="center">
                        <Typography variant="body1">Date format</Typography>
                    </Grid>
                    <Grid size={8} display="flex" justifyContent="flex-end">
                        <FormControl variant="outlined" sx={{minWidth: 250}}>
                            <Select
                                variant="outlined"
                                size="small"
                                value={account.userSettings.dateOrder}
                                onChange={handleDateOrder}
                            >
                                <MenuItem key="MDY" value="MDY">mm/dd/yyyy ({currentDate.toFormat("LL/dd/yyyy")})</MenuItem>
                                <MenuItem key="DMY" value="DMY">dd/mm/yyyy ({currentDate.toFormat("dd/LL/yyyy")})</MenuItem>
                                <MenuItem key="YMD" value="YMD">yyyy-mm-dd ({currentDate.toFormat("yyyy-LL-dd")})</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid size={4} display="flex" alignItems="center">
                        <Typography variant="body1">Week start</Typography>
                    </Grid>
                    <Grid size={8} display="flex" justifyContent="flex-end">
                        <FormControl variant="outlined" sx={{minWidth: 250}}>
                            <Select
                                variant="outlined"
                                size="small"
                                value={(account.userSettings.weekStart as number).toString()}
                                onChange={handleWeekStart}
                            >
                                {WeekDays.map((weekDay, i) => (
                                    <MenuItem key={i} value={i}>{weekDay}</MenuItem>
                                )).filter((_, i) => i == 0 || i == 1 || i == 6)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid size={4} display="flex" alignItems="center">
                        <Typography variant="body1">Show week numbers</Typography>
                    </Grid>
                    <Grid size={8} display="flex" justifyContent="flex-end">
                        <Switch checked={account.userSettings.showWeeks} onChange={handleToggleShowWeekNumbers}/>
                    </Grid>
                </Grid>
            </Container>
        </Stack>
    );
}
