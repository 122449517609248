import * as React from "react";
import {Chip, Paper, Popper, Stack, Typography} from "@mui/material";
import {MbscScheduleEvent, MbscScheduleResource} from "../../utils/utils";
import {DateTime} from "luxon";
import CircleIcon from "@mui/icons-material/Circle";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import {sanitizeGoogleCalendarHtml} from "../../../../utils/sanitize";
import {asLuxonDate} from "../../../../utils/date";
import {LocaleCtx, useLocale} from "../../locale-provider";

export type EventTooltipProps = {
    anchorEl: HTMLButtonElement | null | undefined;
    event?: MbscScheduleEvent;
    resource?: MbscScheduleResource;
}

export function EventTooltip({anchorEl, event, resource}: EventTooltipProps) {
    const open = Boolean(anchorEl);

    if (event === undefined || resource === undefined || event.more) {
        return;
    }

    const renderTimeHeader = (locale: LocaleCtx, start: DateTime, end: DateTime) => {
        if (event.allDay) {
            if (start.toISODate() === end.minus({days: 1}).toISODate()) {
                return (
                    <Typography variant="caption">{start.toFormat(locale.luxonLocale.dateFormat)}</Typography>
                );
            } else {
                return (
                    <Typography variant="caption">
                        {start.toFormat(locale.luxonLocale.dateFormat)} - {end.minus({days: 1}).toFormat(locale.luxonLocale.dateFormat)}
                    </Typography>
                );
            }
        } else if (start.toISODate() === end.toISODate()) {
            return (
                <Typography variant="caption">
                    {start.toFormat(locale.luxonLocale.timeFormat)} - {end.toFormat(locale.luxonLocale.timeFormat)}
                </Typography>
            );
        } else {
            return (
                <Typography variant="caption">
                    {start.toFormat(locale.luxonLocale.dateTimeFormat)} - {end.toFormat(locale.luxonLocale.dateTimeFormat)}
                </Typography>
            );
        }
    }

    const PopupContent = () => {
        const locale = useLocale();
        const start = asLuxonDate(event.start).setZone(event.allDay ? "UTC" : locale.uiTimezone);
        const end = asLuxonDate(event.end).setZone(event.allDay ? "UTC" : locale.uiTimezone);
        const description = sanitizeGoogleCalendarHtml(event.description as string);

        return (
            <Paper elevation={3} sx={{minWidth: 150}}>
                <Stack spacing={1.5} sx={{p: 2, userSelect: "none"}} direction="column">
                    {renderTimeHeader(locale, start, end)}
                    <Stack direction="row" spacing={1} sx={{display: "flex", alignItems: "top"}}>
                        <CircleIcon sx={{color: event.color || resource.color}} fontSize="small"/>
                        <Typography variant="body2" sx={{maxWidth: 300}}>
                            {event.title}
                        </Typography>
                    </Stack>
                    {(event.location || event.workLocation) &&
                        <Stack direction="row" spacing={1} sx={{display: "flex", alignItems: "top"}}>
                            <LocationOnOutlinedIcon fontSize="small"/>
                            <Typography variant="body2"
                                        sx={{maxWidth: 300}}>{event.workLocation && "Working location"}{event.location}</Typography>
                        </Stack>
                    }
                    {event.declinedEvent &&
                        <Stack direction="row" spacing={1} sx={{display: "flex", alignItems: "center"}}>
                            <PeopleOutlineOutlinedIcon fontSize="small"/>
                            <Typography variant="body2">Invitation</Typography>
                            <Chip size="small" label="Declined"/>
                        </Stack>
                    }
                    {description &&
                        <Stack direction="row" spacing={1} sx={{display: "flex", alignItems: "top"}}>
                            <NotesOutlinedIcon fontSize="small"/>
                            <Typography variant="body2" sx={{
                                maxWidth: 300,
                                maxHeight: 300,
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}>
                                <span dangerouslySetInnerHTML={{__html: description}}/>
                            </Typography>
                        </Stack>
                    }
                </Stack>
            </Paper>
        );
    }

    return (
        <Popper
            style={{zIndex: 1000}}
            open={open}
            anchorEl={anchorEl}
            placement="top"
            disablePortal={false}
            modifiers={[
                {
                    name: 'flip',
                    enabled: true,
                    options: {
                        fallbackPlacements: ["left", "right"],
                        altBoundary: true,
                        rootBoundary: 'viewport',
                        padding: 8,
                    },
                },
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        altAxis: true,
                        altBoundary: false,
                        tether: true,
                        rootBoundary: 'viewport',
                        padding: 8,
                    },
                },
            ]}
        >
            <PopupContent/>
        </Popper>
    );
}
