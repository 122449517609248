import * as React from "react";
import {useCallback, useMemo, useRef, useState} from "react";
import {
    addScheduleRow,
    CalendarResult,
    createScheduleEvent,
    deleteScheduleEvent,
    deleteScheduleRow,
    getScheduleEvents,
    inviteResource,
    setNotification,
    updateSchedule,
    updateScheduleEvent,
    updateScheduleRow
} from "../../clients/teamcal-api";
import {useDialogs, useNotifications} from "@toolpad/core";
import {EditEventDialog} from "./edit-event-dialog/edit-event-dialog";
import {
    applyResourceSortOrder,
    buildCreateScheduleEvent,
    buildFromScheduleEvent,
    buildFromScheduleRow,
    buildUpdateScheduleEvent,
    buildUpdateScheduleResource,
    MbscScheduleEvent,
    MbscScheduleResource,
    RowOrderDirections,
    sortResources,
} from "./utils/utils";
import {useAuth} from "../../auth/auth-provider";
import {DeleteEventDialog} from "./delete-event-dialog/delete-event-dialog";
import {TeamCalEventCalendar} from "./event-calendar/teamcal-event-calendar";
import {AddResourceDialog} from "./add-resource-dialog/add-resource-dialog";
import {MemoResourceHeader} from "./event-calendar/resource/resource-header";
import {MemoCalendarToolbar} from "./event-calendar/toolbar/calendar-toolbar";
import {Box, Paper, Typography} from "@mui/material";
import {ResourceHooverMenu} from "./event-calendar/resource/resource-hoover-menu";
import {
    EventChangedPayload,
    EventChangedType,
    ResourceChangedPayload,
    ResourceChangedType
} from "./utils/payload-types";
import {RenameRowDialog} from "./rename-row-dialog/rename-row-dialog";
import {showAPIErrorNotification,} from "../../clients/teamcal-api-notifications";
import {EventcalendarBase} from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";
import {MemoRenderEvent} from "./event-calendar/event/render-event";
import {EventTooltip, EventTooltipProps} from "./event-calendar/event/event-tooltip";
import {DateTime} from "luxon";
import {batchPromiseAll} from "../../utils/promise";
import {MbscCalendarEventData} from "@mobiscroll/react";
import {useSchedule} from "./schedule-provider";
import {
    CtxMenuAction,
    CtxMenuActions,
    CtxMenuEvent,
    ResourceCtxMenu
} from "./event-calendar/resource/resource-ctx-menu";
import {asLuxonDate} from "../../utils/date";
import {useLocale} from "./locale-provider";
import {GoToPurchaseScreen} from "../../utils/purchase-button";
import {useLocation, useNavigate} from "react-router";
import {MemoAddCalendarArrow} from "./tutorial/add-calendar-arrow";
import {applyEventFilter} from "./event-calendar/event/apply-event-filter";
import {intercomSendEvent} from "../../clients/intercom-api";
import {MemoResourceSidebarHeader} from "./event-calendar/resource/resource-sidebar-header";
import {calculateEventDurations, MemoResourceSidebar} from "./event-calendar/resource/resource-sidebar";

const BATCH_EVENT_API_SIZE = 10;
const INVITEE_REFRESH_DELAY = 3000;
type CalendarView = {
    start: DateTime;
    end: DateTime;
}

export function SchedulingView() {
    const dialogs = useDialogs();
    const notifications = useNotifications();
    const {authContext, setAuthContext} = useAuth();
    const locale = useLocale();
    const schedule = useSchedule();
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedDate, setSelectedDate] = useState<string>(DateTime.now().toISODate());
    const [scheduleResources, setScheduleResources] = useState<MbscScheduleResource[]>(() => {
        return schedule.data.rows.map(buildFromScheduleRow).sort(sortResources);
    });
    const [scheduleEvents, setScheduleEvents] = useState<MbscScheduleEvent[]>([]);
    const [eventFilterByName, setEventFilterByName] = useState<string | undefined>(undefined);
    const [isRefreshingEvents, setIsRefreshingEvents] = useState<boolean>(true);
    const [sortModeActive, setSortModeActive] = useState<boolean>(false);
    const [originSortResource, setOriginSortResource] = useState<MbscScheduleResource | undefined>(undefined);
    const calendarInstanceRef = useRef<EventcalendarBase | undefined>(undefined);
    const calendarViewRef = useRef<CalendarView | undefined>(undefined);
    const [inviteeRefreshTimeout, setInviteeRefreshTimeout] = useState<number | undefined>(undefined);
    const [ctxMenuEvent, setCtxMenuEvent] = useState<CtxMenuEvent | undefined>(undefined);
    const [ctxMenuSource, setCtxMenuSource] = useState<"event" | "resource">("event");
    const [copiedEvent, setCopiedEvent] = useState<MbscScheduleEvent | undefined>(undefined);
    const [eventTooltip, setEventTooltip] = useState<EventTooltipProps | undefined>(undefined);
    const [showTotal, setShowTotal] = React.useState(false);

    const filteredEvents = useMemo(() => applyEventFilter(
        scheduleEvents, schedule.data.allDaySettings, schedule.data.outOfOfficeSettings, eventFilterByName
    ), [
        scheduleEvents, schedule.data.allDaySettings, schedule.data.outOfOfficeSettings, eventFilterByName
    ])

    const restrictions = {
        disableAdd: authContext?.account?.restrictions?.add || false,
        disableEdit: authContext?.account?.restrictions?.edit || false,
        disableMove: authContext?.account?.restrictions?.move || false,
        disableDelete: authContext?.account?.restrictions?.delete || false,
    }

    const handleAPIError = (error: any, resource: MbscScheduleResource) => {
        showAPIErrorNotification(
            error,
            resource,
            notifications,
            async () => {
                await loadScheduleEvents([resource])
            },
            () => {
                GoToPurchaseScreen(navigate, location)
            });
    }

    const handleViewChange = async (viewStart: Date, viewEnd: Date, inst: EventcalendarBase) => {
        calendarInstanceRef.current = inst;
        calendarViewRef.current = {
            start: asLuxonDate(viewStart).setZone(locale.uiTimezone, {keepLocalTime: true}),
            end: asLuxonDate(viewEnd).setZone(locale.uiTimezone, {keepLocalTime: true}),
        };

        await loadScheduleEvents(scheduleResources);
    };

    const loadScheduleEvents = async (resources: MbscScheduleResource[]) => {
        if (!calendarViewRef.current || resources.length === 0) {
            return;
        }

        if (inviteeRefreshTimeout) {
            clearTimeout(inviteeRefreshTimeout);
            setInviteeRefreshTimeout(undefined);
        }

        setTimeout(() => {
            // Required to prevent React render error
            setIsRefreshingEvents(true);
        }, 0);

        const eventCalls = await batchPromiseAll(
            resources.map(async row => {
                try {
                    return (await getScheduleEvents(
                        schedule.data.id,
                        row.id as string,
                        calendarViewRef.current!.start.toJSDate(),
                        calendarViewRef.current!.end.toJSDate(),
                    )).map(event => {
                        row.roles.add(event.role);
                        return buildFromScheduleEvent(event, row);
                    });
                } catch (error) {
                    handleAPIError(error, row);
                    return [];
                }
            })
            , BATCH_EVENT_API_SIZE);

        const events = eventCalls.flat();

        // Remove all existing events from the given resource and replace them
        // with the most recent API response.
        const resourceIds = new Set(resources.map(r => r.id));
        setScheduleEvents(scheduleEvents => {
            return [
                ...scheduleEvents.filter(e => !resourceIds.has(e.resource as string)),
                ...events,
            ]
        });

        setScheduleResources(resources => [...resources]);
        setIsRefreshingEvents(false);
    }

    const handleEventsWithInviteesRefresh = (organizerEvent: MbscScheduleEvent, onRefresh?: () => void) => {
        const resourceIds = new Set();

        if (!organizerEvent.organizer) {
            return;
        }

        if (organizerEvent.attendees && organizerEvent.attendees.length > 0) {
            // Check if there are any resources for the given attendees
            const idsSet = new Set(organizerEvent.attendees.map((a => a.email)));
            scheduleResources.filter(r => idsSet.has(r.extId as string)).forEach(r => resourceIds.add(r.id));
        } else if (organizerEvent.attendeesOmitted) {
            // Update all (other) resources which share the same event id because "attendees" was omitted
            const sharedEvents = scheduleEvents.filter(e => e.sharedId === organizerEvent.sharedId);
            sharedEvents.filter(e => e.id !== organizerEvent.id).forEach(e => {
                resourceIds.add(e.resource);
            });
        }

        if (resourceIds.size > 0) {
            const timeout = setTimeout(async () => {
                // Delay, since Google event updates have a lag on Google side until the data is available
                await loadScheduleEvents(scheduleResources.filter(
                    r => resourceIds.has(r.id)
                ))

                setInviteeRefreshTimeout(undefined);
                if (onRefresh) {
                    onRefresh();
                }
            }, INVITEE_REFRESH_DELAY);

            setInviteeRefreshTimeout(timeout);
        }
    }

    const showEditDialog = async (scheduleEvent: MbscScheduleEvent, resourceId?: string) => {
        const savePayload = await dialogs.open(EditEventDialog, {
            event: scheduleEvent,
            availableResources: scheduleResources,
            resourceId: resourceId,
            allowMove: !restrictions.disableMove,
            allowDelete: !restrictions.disableDelete,
            locale: locale,
        });

        if (savePayload) {
            if (savePayload.type === EventChangedType.Delete) {
                const confirmDelete = await showDeleteDialog(savePayload.event);
                if (confirmDelete) {
                    await handleEventChanged(savePayload);
                }
            } else {
                await handleEventChanged(savePayload);
            }
        } else {
            // Reset view
            setScheduleEvents([...scheduleEvents]);
        }
    }

    const showDeleteDialog = async (scheduleEvent: MbscScheduleEvent) => {
        if (authContext?.account?.notifications.filter(n => n === "delete_event").length === 0) {
            const result = await dialogs.open(DeleteEventDialog, scheduleEvent);
            if (result.doNotShowAgain) {
                setAuthContext({
                    ...authContext,
                    account: {
                        ...authContext!.account!,
                        notifications: [
                            ...authContext!.account!.notifications!,
                            "delete_event",
                        ],
                    },
                });

                await setNotification("delete_event");
            }

            return result.delete;
        } else {
            // Skip confirm dialog
            return true;
        }
    }

    const showAddRowDialog = async () => {
        intercomSendEvent("schedule-add-row-open", {"id": schedule.data.id});
        await dialogs.open(AddResourceDialog, {
            resources: scheduleResources,
            onResourceAdded: async (calendar: CalendarResult) => {
                return await handleRowChanged({
                    type: ResourceChangedType.Create,
                    resource: {
                        id: calendar.id,
                        extId: calendar.id,
                        name: calendar.name,
                        visible: true,
                        roles: new Set(),
                    },
                    changedResourceProperties: [],
                })
            },
        });
    }

    const handleRowChanged = async (payload: ResourceChangedPayload): Promise<unknown> => {
        // Queue changes on setState because dialogs will cache scheduleResources!
        const resource = payload.resource;

        try {
            if (resource && payload.type === ResourceChangedType.Create) {
                const row = await addScheduleRow(schedule.data.id, {
                    id: payload.resource.extId,
                    name: payload.resource.name as string,
                    type: "G",
                });

                const newResource = buildFromScheduleRow(row);
                setScheduleResources(scheduleResources => {
                    const resources = [
                        ...scheduleResources,
                        newResource,
                    ];
                    return resources.sort(sortResources);
                });

                await loadScheduleEvents([newResource]);
                intercomSendEvent("schedule-add-row", {
                    "id": schedule.data.id,
                    "name": schedule.data.name,
                    "row": newResource.name
                });
            } else if (resource && payload.type === ResourceChangedType.Update) {
                // Update UI so the update looks instant
                setScheduleResources(scheduleResources => {
                    const resources = [
                        ...scheduleResources.filter(r => r.id !== resource.id),
                        resource,
                    ];
                    return resources.sort(sortResources);
                });

                const row = await updateScheduleRow(
                    schedule.data.id,
                    resource.id as string,
                    buildUpdateScheduleResource(resource, payload.changedResourceProperties),
                );

                setScheduleResources(scheduleResources => {
                    const resources = [
                        ...scheduleResources.filter(r => r.id !== resource.id),
                        buildFromScheduleRow(row),
                    ];
                    return resources.sort(sortResources);
                });

            } else if (payload.type === ResourceChangedType.Delete) {
                await deleteScheduleRow(schedule.data.id, resource.id as string);
                setScheduleResources(scheduleResources => [...scheduleResources.filter(r => r.id !== resource.id)]);
            }
        } catch (error) {
            // Reset all changes
            setScheduleResources(scheduleResources => [...scheduleResources]);
            handleAPIError(error, resource);
            return error;
        }
    }

    const handleEventChanged = async (payload: EventChangedPayload): Promise<unknown> => {
        const event = {...payload.event};
        const resource = scheduleResources.find(r => r.id === payload.resourceId)!;

        if (payload.type === EventChangedType.Create) {
            // Update UI so the update looks instant
            setScheduleEvents(scheduleEvents => [
                ...scheduleEvents,
                event,
            ]);

            try {
                const createdEvent = buildFromScheduleEvent(await createScheduleEvent(
                    schedule.data.id,
                    payload.resourceId,
                    buildCreateScheduleEvent(event),
                ), resource);

                // Update the UI with the API response
                setScheduleEvents(scheduleEvents => {
                    if (scheduleEvents.filter(e => e.id === event.id).length > 0) {
                        return [
                            ...scheduleEvents.filter(e => e.id !== event.id),
                            createdEvent,
                        ];
                    } else {
                        return scheduleEvents;
                    }
                });

                handleEventsWithInviteesRefresh(createdEvent);
            } catch (error) {
                // Reset change - remove event
                setScheduleEvents(scheduleEvents => [
                    ...scheduleEvents.filter(e => e !== event),
                ]);

                handleAPIError(error, resource);
                return error;
            }
        } else if (payload.type === EventChangedType.Invite) {
            try {
                notifications.show(`Inviting "${resource.name}" ...`, {autoHideDuration: 3000});
                await inviteResource(schedule.data.id, payload.oldResourceId as string, event.id as string, [resource.extId]);
                handleEventsWithInviteesRefresh({
                    ...event,
                    attendees: [...(event.attendees || []), {
                        profileId: null,
                        name: null,
                        email: resource.extId,
                        optional: false,
                        user: false,
                        organizer: false,
                        resource: false,
                        status: "",
                    }],
                }, () => {
                    notifications.show(`"${resource.name}" - Invited`, {severity: "success", autoHideDuration: 3000});
                });
            } catch (error) {
                handleAPIError(error, resource);
                return error;
            }
        } else if (payload.type === EventChangedType.Update && payload.changedEventProperties.length > 0) {
            const resourceId = payload.oldResourceId || payload.resourceId;
            // Only if event has moved to a new row:
            const newResourceId = resourceId !== payload.resourceId ? payload.resourceId : undefined;

            // Update UI so the update looks instant
            // Only if event is in list - might have been removed if create failed
            setScheduleEvents(scheduleEvents => {
                if (scheduleEvents.filter(e => e.id === event.id).length > 0) {
                    return [
                        ...scheduleEvents.filter(e => e.id !== event.id),
                        event,
                    ]
                } else {
                    return scheduleEvents;
                }
            });

            try {
                const updatedEvent = buildFromScheduleEvent(await updateScheduleEvent(
                    schedule.data.id,
                    resourceId,
                    event.id as string,
                    buildUpdateScheduleEvent(event, payload.changedEventProperties, newResourceId),
                ), resource);

                // Update the UI with the API response
                // Only if event object wasn't already updated by a new update
                setScheduleEvents(scheduleEvents => {
                    if (scheduleEvents.includes(event)) {
                        return [
                            ...scheduleEvents.filter(e => e !== event),
                            updatedEvent,
                        ];
                    } else {
                        return scheduleEvents
                    }
                });

                handleEventsWithInviteesRefresh(updatedEvent);
            } catch (error) {
                // Reset change - if event object wasn't already updated by a new update
                setScheduleEvents(scheduleEvents => {
                    if (payload.oldEvent && scheduleEvents.includes(event)) {
                        return [
                            ...scheduleEvents.filter(e => e !== event),
                            payload.oldEvent!,
                        ];
                    } else {
                        return scheduleEvents
                    }
                });

                handleAPIError(error, resource);
                return error;
            }
        } else if (payload.type === EventChangedType.Delete) {
            // Update UI so the update looks instant
            setScheduleEvents(scheduleEvents => [...scheduleEvents.filter(e => e.id !== event.id)]);

            try {
                await deleteScheduleEvent(schedule.data.id, payload.resourceId, event.id as string);
                handleEventsWithInviteesRefresh(event);
            } catch (error) {
                // Reset change - add event back
                setScheduleEvents(scheduleEvents => [
                    ...scheduleEvents.filter(e => e.id !== event.id),
                    event,
                ]);

                handleAPIError(error, resource);
                return error;
            }
        }
    }

    const handleRowFilter = useCallback((filteredResourceIds: string[]) => {
        const visibleResourceIds = new Set<string>(filteredResourceIds);

        setScheduleResources(scheduleResources.map(resource => {
            return {
                ...resource,
                visible: visibleResourceIds.has(resource.id as string),
            }
        }))
    }, [scheduleResources]);

    const handleShowTotal = useCallback((enabled: boolean) => {
        setShowTotal(enabled);
    }, []);

    const handleCtxMenu = (args: CtxMenuEvent) => {
        setCtxMenuSource(args.event ? "event" : "resource");

        if (copiedEvent && !args.event) {
            args.event = copiedEvent;
        }

        setCtxMenuEvent(args);
    }

    const handleCtxMenuClose = () => {
        setCtxMenuEvent(undefined);
    }

    const createNewEvent = (date: Date, resourceId: string): MbscScheduleEvent => {
        const start = asLuxonDate(date).setZone(locale.uiTimezone, {keepLocalTime: true});
        return {
            isNew: true,
            title: "",
            organizer: true,
            canInviteGuests: true,
            role: "W",
            outOfOffice: false,
            resource: resourceId,
            start: start.toISO() as string,
            end: start.plus({"minutes": 30}).toISO() as string,
        };
    }

    const createNewEventFromExisting = (scheduleEvent: MbscScheduleEvent, newResourceId: string, newDate?: Date): MbscScheduleEvent => {
        let start = scheduleEvent.start;
        let end = scheduleEvent.end;

        if (newDate) {
            const newStart = asLuxonDate(newDate).setZone(locale.uiTimezone, {keepLocalTime: true});
            const diff = newStart.diff(asLuxonDate(scheduleEvent.start));

            start = asLuxonDate(scheduleEvent.start).plus(diff).toISO() as string;
            end = asLuxonDate(scheduleEvent.end).plus(diff).toISO() as string;
        }

        return {
            ...scheduleEvent,
            id: undefined,
            isNew: true,
            organizer: true,
            canInviteGuests: true,
            attendeesOmitted: false,
            attendees: [],
            role: "W",
            outOfOffice: false,
            sharedId: undefined,
            resource: newResourceId,
            start: start,
            end: end,
        }
    }

    const handleCtxMenuAction = async (args: CtxMenuAction) => {
        switch (args.type) {
            case CtxMenuActions.Add: {
                const event = createNewEvent(args.date, args.resourceId);
                await showEditDialog(event, event.resource as string);
                break;
            }
            case CtxMenuActions.Edit: {
                await showEditDialog(args.event!, args.resourceId);
                break;
            }
            case CtxMenuActions.Copy: {
                setCopiedEvent(args.event!);
                notifications.show("Event copied. Right click a cell to paste it.", {autoHideDuration: 3000});
                break;
            }
            case CtxMenuActions.Paste: {
                const event = createNewEventFromExisting(args.event!, args.resourceId, args.date);
                await showEditDialog(event, event.resource as string);
                break;
            }
            case CtxMenuActions.Duplicate: {
                const event = createNewEventFromExisting(args.event!, args.resourceId, undefined);
                await showEditDialog(event, event.resource as string);
                break;
            }
        }
    }

    const handleTooltip = (show: boolean, args: EventTooltipProps) => {
        if (show && !eventTooltip) {
            setEventTooltip(args)
        } else {
            setEventTooltip(undefined);
        }
    }

    const renderHeader = () => {
        return (
            <MemoCalendarToolbar
                instance={calendarInstanceRef.current}
                isSharedMode={false}
                resources={scheduleResources}
                onAddCalendar={showAddRowDialog}
                eventFilters={{
                    eventName: eventFilterByName,
                    allDayEvents: schedule.data.allDaySettings,
                    outOfOfficeEvents: schedule.data.outOfOfficeSettings,
                }}
                onEventFilterChange={(filterName: string, value?: string) => {
                    if (filterName === "eventName") {
                        setEventFilterByName(value);
                    } else {
                        schedule.applyChanges([{prop: filterName, value: value}]);
                    }
                }}
                selectedDate={selectedDate}
                onDateChange={setSelectedDate}
                isRefreshing={isRefreshingEvents}
                onRefresh={async () => {
                    await loadScheduleEvents(scheduleResources);
                }}
                onEventClicked={async (event: MbscScheduleEvent) => {
                    await showEditDialog(event, event.resource as string)
                }}
                onJumpToEvent={(event: MbscScheduleEvent) => {
                    const date = asLuxonDate(event.start).setZone(event.allDay ? "UTC": locale.uiTimezone);
                    setSelectedDate(date.toISODate()!);
                }}
            />
        );
    }

    const renderResourceHeader = () => {
        return (
            <MemoResourceHeader
                resources={scheduleResources}
                onFilterChanged={handleRowFilter}
                onTotalToggle={handleShowTotal}
            />
        );
    }

    const renderResource = (resource: MbscScheduleResource) => {
        const activeRowModeSort = () => {
            setSortModeActive(true);
            setOriginSortResource(resource);

            notifications.show(`Move "${resource.name}" by clicking on another row.`, {
                severity: "info",
                autoHideDuration: 5000,
            });
        }

        const cancelRowSortMode = () => {
            setOriginSortResource(undefined);
            setSortModeActive(false);
        }

        const setResourceSortOrder = async (dir: RowOrderDirections, curResource: MbscScheduleResource, originResourceId?: string) => {
            cancelRowSortMode();

            const previousRowOrders = scheduleResources.filter(r => r.order !== undefined && r.order !== null).map(r => r.id as string);
            applyResourceSortOrder(scheduleResources, dir, curResource.id as string, originResourceId);
            setScheduleResources([...scheduleResources.sort(sortResources)]);

            try {
                await updateSchedule(schedule.data.id, {
                    rowOrder: scheduleResources.map(r => r.id as string),
                    previousRowOrder: previousRowOrders
                });
            } catch (error) {
                handleAPIError(error, resource);
            }
        }

        return (
            <ResourceHooverMenu
                resource={resource}
                sortActive={sortModeActive}
                onCancelRowOrderChange={cancelRowSortMode}
                onSetBeforeRowOrder={async (resource: MbscScheduleResource) => {
                    await setResourceSortOrder(RowOrderDirections.Before, originSortResource!, resource.id as string);
                }}
                onChangeRowOrder={async (resource: MbscScheduleResource, dir: RowOrderDirections) => {
                    if (dir === RowOrderDirections.Before) {
                        activeRowModeSort();
                    } else {
                        await setResourceSortOrder(dir, resource);
                    }
                }}
                onRemoveRow={async () => {
                    await handleRowChanged({
                        type: ResourceChangedType.Delete,
                        resource: resource,
                        changedResourceProperties: [],
                    });
                }}
                onRenameRow={async () => {
                    const name = await dialogs.open(RenameRowDialog, resource);
                    if (name && name.trim() !== "") {
                        resource.name = name;

                        await handleRowChanged({
                            type: ResourceChangedType.Update,
                            resource: resource,
                            changedResourceProperties: ["name"],
                        });
                    }
                }}
                onSetRowColor={async (color: string) => {
                    resource.color = color;

                    await handleRowChanged({
                        type: ResourceChangedType.Update,
                        resource: resource,
                        changedResourceProperties: ["color"],
                    });
                }}
            >
                <Box
                    sx={{display: "flex", height: "100%", p: 1, overflow: "hidden"}}
                    alignItems="center">
                    <Typography noWrap textOverflow="ellipsis">{resource.name}</Typography>
                </Box>
            </ResourceHooverMenu>
        )
    }

    const renderSidebarHeader = useCallback(() => {
        return (<MemoResourceSidebarHeader/>);
    }, []);

    const durationSummaries = useMemo(() => {
        if (!calendarViewRef.current) {
            return {};
        }

        return showTotal ? calculateEventDurations({
            scheduleEvents: filteredEvents,
            viewStart: calendarViewRef.current.start,
            viewEnd: calendarViewRef.current.end,
            workWeekStart: schedule.data.showWeekends ? 0 : schedule.data.workWeekStart,
            workWeekEnd: schedule.data.showWeekends ? 6 : schedule.data.workWeekEnd,
            workDayStart: schedule.data.showWorkHours ? schedule.data.workDayStart : 0,
            workDayEnd: schedule.data.showWorkHours ? schedule.data.workDayEnd : 60 * 24,
            uiTimezone: locale.uiTimezone,
        }): {};
    }, [filteredEvents,
        locale.uiTimezone,
        showTotal,
        schedule.data.showWeekends,
        schedule.data.workWeekStart,
        schedule.data.workWeekEnd,
        schedule.data.showWorkHours,
        schedule.data.workDayStart,
        schedule.data.workDayEnd
    ]);

    const renderSidebar = useCallback((resource: MbscScheduleResource) => {
        const summary = durationSummaries[resource.id as string];
        const availableHours = summary ? summary.availableHours: undefined;
        const scheduledHours = summary ? summary.scheduledHours: undefined;
        return (<MemoResourceSidebar availableHours={availableHours} scheduledHours={scheduledHours}/>);
    }, [durationSummaries]);

    const renderEvent = useCallback((args: MbscCalendarEventData) => {
        const event = args.original as MbscScheduleEvent;
        return (
            <MemoRenderEvent
                eventSize={schedule.data.viewMode}
                title={event.title}
                location={event.location}
                declinedEvent={event.declinedEvent}
                backgroundColor={args.color}
                workLocation={event.workLocation}
            />
        );
    }, [schedule.data.viewMode]);

    return (
        <Paper className="tc-scheduling-view" square elevation={1}>
            {scheduleResources.length > 0 ? (
                <TeamCalEventCalendar
                    viewTimeframe={schedule.data.viewTimeframe!}
                    selectedZoom={schedule.data.viewZoom!}
                    selectedDate={selectedDate}
                    workDayStart={schedule.data.workDayStart}
                    workDayEnd={schedule.data.workDayEnd}
                    workWeekStart={schedule.data.workWeekStart}
                    workWeekEnd={schedule.data.workWeekEnd}
                    showWeekNumbers={locale.showWeekNumbers}
                    showWeekends={schedule.data.showWeekends}
                    showWorkHours={schedule.data.showWorkHours}
                    locale={locale.mbscLocale}
                    inviteOnlyMode={schedule.data.moveAction === "I"}
                    events={filteredEvents}
                    resources={scheduleResources}
                    timezone={locale.uiTimezone}
                    allowAdd={!restrictions.disableAdd}
                    allowEdit={!restrictions.disableEdit}
                    allowMove={!restrictions.disableMove}
                    renderHeader={renderHeader}
                    renderResourceHeader={renderResourceHeader}
                    renderResource={renderResource}
                    renderSidebarHeader={showTotal ? renderSidebarHeader: undefined}
                    renderSidebar={showTotal ? renderSidebar: undefined}
                    renderEvent={renderEvent}
                    onEventChanged={handleEventChanged}
                    onViewChange={handleViewChange}
                    onEditEvent={showEditDialog}
                    onCtxMenu={handleCtxMenu}
                    onTooltip={handleTooltip}
                />
            ) : (
                <MemoAddCalendarArrow onAddCalendar={showAddRowDialog}/>
            )}
            <ResourceCtxMenu
                source={ctxMenuSource}
                menuEvent={ctxMenuEvent}
                allowAdd={!restrictions.disableAdd}
                allowEdit={!restrictions.disableEdit}
                onClose={handleCtxMenuClose}
                onAction={handleCtxMenuAction}
            />
            <EventTooltip
                event={eventTooltip?.event}
                resource={eventTooltip?.resource}
                anchorEl={eventTooltip?.anchorEl}
            />
        </Paper>
    );
}
