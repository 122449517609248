import writeXlsxFile, {Cell, SheetData} from "write-excel-file";
import {EventcalendarBase} from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";
import {asLuxonDate} from "../../../../utils/date";
import {LocaleCtx} from "../../locale-provider";

const RowHeader = [
    {value: "Name", fontWeight: "bold"} as Cell,
    {value: "Start Date", fontWeight: "bold"} as Cell,
    {value: "End Date", fontWeight: "bold"} as Cell,
    {value: "Duration (hours)", fontWeight: "bold"} as Cell,
    {value: "Duration (days)", fontWeight: "bold"} as Cell,
    {value: "Title", fontWeight: "bold"} as Cell,
    {value: "Location", fontWeight: "bold"} as Cell,
] as SheetData;

export async function Export2Excel(locale: LocaleCtx, instance: EventcalendarBase, name: string) {
    // @ts-expect-error Access private member _events
    const data = instance._events.map((event, i) => {
        // Load dates as UTC because Excel excepts all in UTC
        const start = asLuxonDate(event.start as string).setZone(event.allDay ? "UTC": locale.uiTimezone).setZone("UTC", {keepLocalTime: true});
        const end = asLuxonDate(event.end as string).setZone(event.allDay ? "UTC": locale.uiTimezone).setZone("UTC", {keepLocalTime: true});

        return [
        {
            type: String,
            value: instance._resourcesMap![event.resource as string].name as string,
        } as Cell,
        {
            type: Date,
            value: start.toJSDate(),
            format: event.allDay ? 'yyyy-mm-dd' : 'yyyy-mm-dd hh:mm',
        } as Cell,
        {
            type: Date,
            value: (event.allDay ? end.minus({day: 1}): end).toJSDate(),
            format: event.allDay ? 'yyyy-mm-dd' : 'yyyy-mm-dd hh:mm',
        } as Cell,
        {
            type: "Formula",
            value: event.allDay ? `=C${i + 2}+1-B${i + 2}` : `=C${i + 2}-B${i + 2}`,
            format: '[h]:mm',
        } as Cell,
        {
            type: "Formula",
            value: event.allDay ? `=C${i + 2}+1-B${i + 2}` : `=C${i + 2}-B${i + 2}`,
            format: '0.00',
        } as Cell,
        {
            type: String,
            value: event.title as string,
        },
        {
            type: String,
            value: event.location as string,
        } as Cell,
    ] as SheetData});

    await writeXlsxFile([
        RowHeader,
        ...data,
    ], {
        columns: RowHeader.map(() => ({width: 20})),
        fileName: `${name}.xlsx`,
        sheet: "Export",
    })
}
