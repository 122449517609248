import * as React from "react";
import {useCallback, useEffect} from "react";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {DialogProps, useDialogs} from "@toolpad/core";
import {intercomSendEvent} from "../../../clients/intercom-api";
import {useAuth} from "../../../auth/auth-provider";
import videoHowToEvents from "./videos/how-to-events.gif"
import videoHowToRows from "./videos/how-to-rows.gif"
import videoHowToConfig from "./videos/how-to-config.gif"
import videoHowToAddCalendars from "./videos/how-to-add-calendars.gif"
import {setNotification} from "../../../clients/teamcal-api";

const STEPS = [
    {
        description: 'Use the mouse to drag, resize, or add an event. Click to edit.',
        video: videoHowToEvents,
    },
    {
        description: 'Click a row to change the order, color, or name.',
        video: videoHowToRows,
    },
    {
        description: 'Use the toolbar to adjust your schedule view.',
        video: videoHowToConfig,
    },
    {
        description: 'Click "Add Calendars" to get started.',
        video: videoHowToAddCalendars,
    }
];

function TutorialDialog({open, onClose}: DialogProps<void>) {
    const [currentStep, setCurrentStep] = React.useState<number>(0);

    const handleClose = async () => {
        await onClose();
    };

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle bgcolor="primary.main" sx={{color: "primary.contrastText"}}>
                Welcome to TeamCal
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Typography sx={{mt: 4, mb: 4, textAlign: "center"}} variant="body1">
                        {STEPS[currentStep].description}
                    </Typography>
                    <Paper elevation={2}>
                        <img src={STEPS[currentStep].video} width="512" height="384"/>
                    </Paper>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{width: "100%", pl: 2, pr: 2, pb: 2}} display="flex">
                    <span style={{flexGrow: 1}}></span>
                    {currentStep == STEPS.length - 1 ? (
                        <Button color="primary" variant="contained" onClick={handleClose}>Close</Button>
                    ) : (
                        <Button color="secondary" variant="text" onClick={handleClose}>Close</Button>
                    )}
                    {currentStep < STEPS.length - 1 &&
                        <Button sx={{ml: 1}} color="primary" variant="contained" onClick={handleNext}>Next</Button>
                    }
                </Box>
            </DialogActions>
        </Dialog>
    );
}

export function Tutorial() {
    const dialogs = useDialogs();
    const {authContext, setAuthContext} = useAuth();
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const showTutorial = useCallback(async () => {
        intercomSendEvent("tutorial-open");
        await dialogs.open(TutorialDialog);
        intercomSendEvent("tutorial-completed");

        setAuthContext({
            ...authContext,
            account: {
                ...authContext!.account!,
                notifications: [
                    ...authContext!.account!.notifications!,
                    "intro_event",
                ],
            },
        });

        await setNotification("intro_event");
    }, [authContext, dialogs, setAuthContext]);

    useEffect(() => {
        if (!isOpen && authContext!.account!.notifications.filter(n => n === "intro_event").length === 0) {
            setIsOpen(true);
            showTutorial().then(() => {
                setIsOpen(false);
            });
        }
    }, [authContext, isOpen, showTutorial]);

    return (<div></div>);
}
