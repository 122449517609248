import React from "react";
import {Link, Stack} from "@mui/material";

export function SchedulingFooterBar() {
    return (
        <Stack direction="row" spacing={2} className="tc-hidden-print"
               sx={{height: 20, pr: 2, display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
            <Link href="https://www.teamcalapp.com/category/news/"
                  variant="button" underline="none" color="secondary" sx={{fontSize: 12}}
                  target="_blank" rel="noreferrer">
                What&apos;s new
            </Link>
            <span>|</span>
            <Link href="mailto:info@teamcalapp.com"
                  variant="button" underline="none" color="secondary" sx={{fontSize: 12}}>
                Feedback & Support
            </Link>
            <span>|</span>
            <Link href="https://www.teamcalapp.com/terms/"
                  variant="button" underline="none" color="secondary" sx={{fontSize: 12}}
                  target="_blank" rel="noreferrer">
                Legal
            </Link>
            <span>|</span>
            <Link href="https://www.teamcalapp.com/privacy/"
                  variant="button" underline="none" color="secondary" sx={{fontSize: 12}}
                  target="_blank" rel="noreferrer">
                Privacy
            </Link>
        </Stack>
    );
}
