import * as React from "react";
import {useEffect, useState} from "react";
import {Box, CircularProgress, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {MbscScheduleResource} from "../../utils/utils";
import {CalendarResult, searchCalendars} from "../../../../clients/teamcal-api";
import Button from "@mui/material/Button";

export interface CalendarResultWithDisplayName extends CalendarResult {
    displayName: string;
}

export function FromGoogleCalendar({resources, onCalendarAdded}: {
    resources: MbscScheduleResource[],
    onCalendarAdded: (calendar: CalendarResultWithDisplayName) => void
}) {
    const [googleCalendars, setGoogleCalendars] = useState<CalendarResultWithDisplayName[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const loadGoogleCalendarList = async () => {
        const calendars = await searchCalendars("", "calendars", false, 250);

        const withDisplayName = calendars.map(c => {
            let displayName;

            if (c.id !== c.name && c.name.trim() !== "" && !c.id.endsWith("calendar.google.com")) {
                displayName = `${c.name} (${c.id})`;
            } else {
                displayName = c.name || c.id;
            }

            return {
                ...c,
                displayName: displayName,
            };
        });

        return withDisplayName.sort((c1, c2) => {
            return c1.displayName.toLowerCase().localeCompare(c2.displayName.toLowerCase());
        });
    }

    useEffect(() => {
        let ignore = false;
        loadGoogleCalendarList().then(calendars => {
            if (!ignore) {
                setGoogleCalendars(calendars);
            }
        }).finally(() => {
            setIsLoading(false);
        })

        return () => {
            ignore = true
        };
    }, []);

    const resourceExtIds = new Set(resources.map(r => r.extId));

    return (
        <List sx={{width: "100%", height: 350, overflowY: "auto"}}>
            {isLoading &&
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%"
                }}>
                    <CircularProgress/>
                </Box>
            }
            {googleCalendars.map(calendar => {
                return (
                    <ListItem
                        key={calendar.id}
                        secondaryAction={
                        <Button
                            color="primary"
                            disabled={resourceExtIds.has(calendar.id)}
                            onClick={() => {onCalendarAdded(calendar)}}
                        >
                            Add
                        </Button>
                        }
                        disablePadding
                    >
                        <ListItemButton onClick={() => {onCalendarAdded(calendar)}}>
                            <ListItemText>
                                {calendar.displayName}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}
