import * as React from "react";
import {Box, Divider, IconButton, Stack, Toolbar, Tooltip, useMediaQuery} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Button from "@mui/material/Button";
import {DateSelector} from "./date-selector";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RefreshIcon from "@mui/icons-material/Refresh";
import {ZoomSelector} from "./zoom-selector";
import {ViewTimeframeSelector} from "./view-timeframe-selector";
import {ViewSettingsSelector} from "./view-settings-selector";
import {SearchEvents} from "./search-events";
import {MbscScheduleEvent, MbscScheduleResource} from "../../utils/utils";
import {EventFilters, EventFilterSelector} from "./event-filter-selector";
import {CloseAction, ShareScheduleDialog} from "../../share-schedule-dialog/share-schedule-dialog";
import {useDialogs} from "@toolpad/core";
import ShareIcon from "@mui/icons-material/Share";
import SettingsIcon from "@mui/icons-material/Settings";
import {SettingDialog} from "../../settings-dialog/settings-dialog";
import {asLuxonDate, nextDaySkipWeekend, previousDaySkipWeekend} from "../../../../utils/date";
import {useSchedule} from "../../schedule-provider";
import {EventcalendarBase} from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";
import {ExportActions} from "./export-actions";
import {GoToPurchaseScreen} from "../../../../utils/purchase-button";
import {useLocation, useNavigate} from "react-router";
import {AutoRefresh} from "./auto-refresh";
import {useAuth} from "../../../../auth/auth-provider";
import {hasFeature} from "../../../../utils/auth";

export type ToolbarActions = {
    instance: EventcalendarBase | undefined,
    isSharedMode: boolean,
    resources: MbscScheduleResource[],
    onAddCalendar: () => Promise<void>,
    eventFilters: EventFilters,
    onEventFilterChange: (filterName: string, value?: string) => void,
    selectedDate: string,
    onDateChange: (date: string) => void,
    isRefreshing: boolean,
    onRefresh: () => void,
    onEventClicked: (event: MbscScheduleEvent) => void,
    onJumpToEvent: (event: MbscScheduleEvent) => void,
}

export function CalendarToolbar({
                                    instance,
                                    isSharedMode,
                                    resources,
                                    onAddCalendar,
                                    selectedDate,
                                    eventFilters,
                                    onEventFilterChange,
                                    onDateChange,
                                    isRefreshing,
                                    onRefresh,
                                    onEventClicked,
                                    onJumpToEvent,
                                }: ToolbarActions) {
    const {authContext} = useAuth();
    const dialogs = useDialogs();
    const schedule = useSchedule();
    const location = useLocation();
    const navigate = useNavigate();

    const dateChangeTitle = () => {
        if (schedule.data.viewTimeframe?.startsWith("W")) {
            return "week";
        } else if (schedule.data.viewTimeframe?.startsWith("M")) {
            return "month";
        } else {
            return "day";
        }
    }

    const previousDate = () => {
        const type = dateChangeTitle();
        const date = asLuxonDate(selectedDate);

        if (type === "day" && !schedule.data.showWeekends) {
            onDateChange(previousDaySkipWeekend(date, schedule.data.workWeekStart, schedule.data.workWeekEnd).toISODate() as string);
        } else {
            onDateChange(date.minus({[type]: 1}).toISODate() as string);
        }
    }

    const nextDate = () => {
        const type = dateChangeTitle();
        const date = asLuxonDate(selectedDate);

        if (type === "day" && !schedule.data.showWeekends) {
            onDateChange(nextDaySkipWeekend(date, schedule.data.workWeekStart, schedule.data.workWeekEnd).toISODate() as string);
        } else {
            onDateChange(date.plus({[type]: 1}).toISODate() as string);
        }
    }

    const handleSharedSchedule = async () => {
        const result = await dialogs.open(ShareScheduleDialog, {
            scheduleId: schedule.data.id,
            featureAvailable: hasFeature("sharing", authContext),
        });

        if (result.type === CloseAction.Upgrade) {
            GoToPurchaseScreen(navigate, location);
        }
    }

    const handleSettings = async () => {
        const settings = await dialogs.open(SettingDialog, {schedule: schedule.data});
        if (settings) {
            schedule.applyChanges([
                {prop: "timezone", value: settings.timezone},
                {prop: "workWeekStart", value: settings.workWeekStart},
                {prop: "workWeekEnd", value: settings.workWeekEnd},
                {prop: "workDayStart", value: settings.workDayStart},
                {prop: "workDayEnd", value: settings.workDayEnd},
                {prop: "moveAction", value: settings.moveAction},
            ]);
        }
    }

    const isPhoneScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const isSmallTableScreen = useMediaQuery(theme => theme.breakpoints.between("sm", "md"));

    return (
        <Toolbar sx={{width: "100%", backgroundColor: "alternate.main"}} className="tc-hidden-print">
            <Stack direction="row" sx={{display: "flex", width: "100%"}} spacing={1}>
                {!isPhoneScreen &&
                    <Tooltip title={`Previous ${dateChangeTitle()}`}>
                        <IconButton onClick={previousDate} color="secondary">
                            <KeyboardArrowLeftIcon/>
                        </IconButton>
                    </Tooltip>
                }
                <DateSelector selectedDate={selectedDate} onChange={onDateChange}/>
                {!isPhoneScreen &&
                    <Tooltip title={`Next ${dateChangeTitle()}`}>
                        <IconButton onClick={nextDate} color="secondary">
                            <KeyboardArrowRightIcon/>
                        </IconButton>
                    </Tooltip>
                }
                <Box sx={{pl: 1, pr: 1}}>
                    <Divider orientation="vertical"/>
                </Box>

                <ViewTimeframeSelector/>
                <ZoomSelector/>
                <ViewSettingsSelector/>

                {!isPhoneScreen &&
                    <Box sx={{pl: 1, pr: 1}}>
                        <Divider orientation="vertical"/>
                    </Box>
                }

                {!isSharedMode && !isPhoneScreen &&
                    <SearchEvents
                        resources={resources}
                        scheduleId={schedule.data.id}
                        onEventClicked={onEventClicked}
                        onJumpToEvent={onJumpToEvent}
                    />
                }
                {!isPhoneScreen &&
                    <EventFilterSelector filter={eventFilters} onChange={onEventFilterChange}/>
                }
                {!isSharedMode && !isPhoneScreen && !isSmallTableScreen &&
                    <Box sx={{pl: 1, pr: 1}}>
                        <Divider orientation="vertical"/>
                    </Box>
                }
                {!isSharedMode && !isPhoneScreen && !isSmallTableScreen &&
                    <Tooltip title="Add calendar">
                        <Button
                            color="secondary"
                            onClick={onAddCalendar}
                            startIcon={<PersonAddIcon/>}
                        >
                            Calendar
                        </Button>
                    </Tooltip>
                }

                <span style={{flexGrow: 1}}></span>

                {!isPhoneScreen && !isSmallTableScreen &&
                    <ExportActions instance={instance}/>
                }
                {!isSharedMode && !isPhoneScreen && !isSmallTableScreen &&
                    <Tooltip title="Share schedule">
                        <IconButton onClick={handleSharedSchedule} color="secondary">
                            <ShareIcon/>
                        </IconButton>
                    </Tooltip>
                }
                {!isSharedMode && !isPhoneScreen && !isSmallTableScreen &&
                    <Tooltip title="Schedule settings">
                        <IconButton onClick={handleSettings} color="secondary">
                            <SettingsIcon/>
                        </IconButton>
                    </Tooltip>
                }
                {!isPhoneScreen && !isSmallTableScreen &&
                    <Tooltip title="Refresh events">
                        <span>
                            <IconButton onClick={onRefresh} disabled={isRefreshing} color="secondary">
                                <RefreshIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
                }
                {isSharedMode && !isPhoneScreen && !isSmallTableScreen &&
                    <AutoRefresh onRefresh={onRefresh} onDateChange={onDateChange}/>
                }
            </Stack>
        </Toolbar>
    );
}

export const MemoCalendarToolbar = React.memo(CalendarToolbar, (prevProps: Readonly<ToolbarActions>, nextProps: Readonly<ToolbarActions>) => {
    // Prevent frequent re-rendering unless visible property changes
    return prevProps.isRefreshing === nextProps.isRefreshing &&
        prevProps.selectedDate === nextProps.selectedDate &&
        prevProps.eventFilters.eventName === nextProps.eventFilters.eventName &&
        prevProps.eventFilters.allDayEvents === nextProps.eventFilters.allDayEvents &&
        prevProps.eventFilters.outOfOfficeEvents === nextProps.eventFilters.outOfOfficeEvents &&
        prevProps.resources.map(r => r.extId).join(",") === nextProps.resources.map(r => r.extId).join(",");
});
