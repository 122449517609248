import {EventcalendarBase} from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";
import {GenerateJpeg} from "./image";
import {jsPDF} from "jspdf";

export async function Export2Pdf(instance: EventcalendarBase, name: string): Promise<void> {
    const {dataUrl, width, height} = await GenerateJpeg(instance);
    const ratio = width / height;
    const pageWidth = Math.min(7200);
    const pageHeight = pageWidth / ratio;

    const doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [pageWidth, pageHeight],
        compress: false,
    });

    doc.setProperties({
        title: name,
        creator: "TeamCal"
    });

    doc.addImage(dataUrl, "JPEG", 0, 0, pageWidth, pageHeight, "schedule", "SLOW", 0);
    doc.save(`${name}.pdf`);
}
