import * as React from "react";
import {useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Typography
} from "@mui/material";
import {DialogProps} from "@toolpad/core";
import {MbscScheduleEvent} from "../utils/utils";

export type DeleteEventPayload = {
    delete: boolean;
    doNotShowAgain?: boolean;
}

export function DeleteEventDialog({payload, open, onClose}: DialogProps<MbscScheduleEvent, DeleteEventPayload>) {
    const [doNotShowAgain, setDoNotShowAgain] = useState<boolean>(false);

    const handleCancel = async () => {
        await onClose({delete: false});
    }

    const handleDelete = async () => {
        await onClose({delete: true, doNotShowAgain: doNotShowAgain});
    }

    return (
        <Dialog fullWidth open={open} onClose={handleCancel}>
            <DialogTitle>
                Confirm event deletion
            </DialogTitle>
            <DialogContent>
                <Typography>
                    This will delete the event &quot;{payload.title}&quot; from Google Calendar.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Box sx={{width: "100%", pl: 2, pr: 2, pb: 2}} display="flex">
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={doNotShowAgain}
                                               onChange={() => {
                                                   setDoNotShowAgain(!doNotShowAgain)
                                               }}/>}
                            label="Do not show again"/>
                    </FormGroup>
                    <span style={{flexGrow: 1}}></span>
                    <Button onClick={handleCancel} color="secondary">Cancel</Button>
                    <Button onClick={handleDelete} variant="contained" sx={{ml: 1}}>Delete</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
