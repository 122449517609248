export async function fetchWithRetry(input: Request, retries: number) {
    if (input.method === "GET") {
        try {
            const response = await fetch(input);

            // Retry all GET requests which return a GATEWAY error
            if (response.status > 500 && retries > 0) {
                return await fetchWithRetry(input, retries - 1);
            } else {
                return response;
            }
        } catch (error) {
            // Retry all Exceptions
            if (retries > 0) {
                return await fetchWithRetry(input, retries - 1);
            } else {
                throw error;
            }
        }
    } else {
        return await fetch(input);
    }
}

export async function retryFetch(input: Request) {
    return fetchWithRetry(input, 3);
}
